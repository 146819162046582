import "./ServiceTile.scss";

import React from "react";

export default ({ tel, timePeriods, white }: any) => {
  timePeriods = timePeriods || [
    { title: "Werktags:", period: "8 - 20 h" },
    { title: "Samstags:", period: "8 - 14 h" },
  ];

  tel = tel || "0521 7000 720";

  return (
    <div className="ServiceTileWrapper">
      <div className="ServiceTile" style={white ? { color: "white" } : {}}>
        <div className="TimePeriods">
          {timePeriods.map(
            (item: { title: string; period: string }, key: number) => (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
                key={key}
              >
                <span style={{ marginRight: "10px" }}>{item.title}</span>
                <span>{item.period}</span>
              </div>
            )
          )}
        </div>

        <div>
          <a
            href={"tel:" + tel.replace(/\s|-/g, "")}
            className="tel"
            style={white ? { color: "white" } : {}}
          >
            <img
              src={
                white
                  ? "/assets/images/icons/service-white.svg"
                  : "/assets/images/icons/service.svg"
              }
              alt=""
              style={{
                marginRight: 10,
                marginTop: 8,
                marginBottom: -8,
                display: "inline-block",
              }}
            />
            {tel}
          </a>
          <br />
          <span className="mehrwerkHint">Mehrwerk GmbH</span>
        </div>
      </div>
    </div>
  );
};

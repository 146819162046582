import "./Dashboard.scss";

import {
  faAngleRight,
  faCommentAltLines,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Grid } from "@material-ui/core";
import React from "react";
import { Fragment } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import Backlink from "../../components/Backlink/Backlink";
import Box from "../../components/Box/Box";
import DashboardBox from "../../components/DashboardBox/DashboardBox";
import Headline from "../../components/Headline/Headline";
import InnerLayout from "../../components/InnerLayout/InnerLayout";
import { Layout } from "../../components/Layout/Layout";
import { Loading } from "../../components/Loading";
import ResponsiveTable from "../../components/ResponsiveTable/ResponsiveTable";
import { CashbackComplaint } from "../../interfaces/cashbackComplaint";
import { CashoutProfile } from "../../interfaces/cashoutProfile";
import Shop from "../../interfaces/shop";
import Helper from "../../services/helper";
import CashbackManager from "../../services/manager/CashbackManager";
import StateManager from "../../services/manager/StateManager";

interface Props {}

interface State {
  cashoutProfile?: CashoutProfile;
  complaints: CashbackComplaint[];
  confirmedCashback?: number;
  pendingCashback?: number;
  loading: boolean;
  hasClickouts: boolean;
}

export default class Complaints extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      complaints: [],
      loading: true,
      hasClickouts: false,
    };
  }

  async componentDidMount() {
    StateManager.resetProductsFilter();
    StateManager.resetShopFilter();

    const result = await Promise.all([
      CashbackManager.getCashoutProfile(),
      CashbackManager.findComplaints(),
      CashbackManager.getCashbackAmountSum("confirmed"),
      CashbackManager.getCashbackAmountSum("open"),
      CashbackManager.findLastClickoutShops(),
    ]);

    const complaints = result[1].items;
    const shopPromises: any[] = [];
    const shops = await Promise.all(shopPromises);
    const clickoutShops = result[4];

    if (clickoutShops.length > 0) {
      this.setState({
        hasClickouts: true,
      });
    }

    // Get shop objects to fetch name
    complaints.forEach((complaint: CashbackComplaint) => {
      shopPromises.push(CashbackManager.findShop(complaint.shopId));

      shops.forEach((shop: Shop) => {
        if (shop.id === complaint.shopId) {
          complaint.shopName = shop.name;
        }
      });
    });

    this.setState({
      cashoutProfile: result[0],
      complaints,
      confirmedCashback: result[2],
      pendingCashback: result[3],
      loading: false,
    });
  }

  render() {
    return (
      <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Nachbuchungsanfragen - OLBplus Vorteilsportal</title>
        </Helmet>
        <div>
          {this.state.loading && (
            <InnerLayout>
              <Loading />
            </InnerLayout>
          )}

          {!this.state.loading && (
            <InnerLayout>
              <Box>
                <Backlink label="Zurück zur Übersicht" to="/profil" />

                <Grid container spacing={2}>
                  <Grid item xs={12} md={8}>
                    <Grid item xs={12}>
                      <Headline type="h1">Ihre Nachbuchungsanfragen</Headline>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={12} md={8}>
                    <Grid item xs={12}>
                      <ResponsiveTable
                        options={{
                          pagination: true,
                        }}
                        columns={[
                          {
                            name: "createdAt",
                            label: "Datum",
                            customBodyRender: (value: any) =>
                              Helper.formatDate(value),
                            textAlign: "left",
                          },
                          {
                            name: "shopName",
                            notCollapseOnMobile: true,
                            label: "Shop",
                            textAlign: "left",
                          },
                          {
                            name: "transactionDate",
                            label: "Einkaufsdatum",
                            customBodyRender: (value: any) =>
                              Helper.formatDate(value),
                            textAlign: "left",
                          },
                          {
                            name: "price",
                            label: "Warenkorbwert",
                            customBodyRender: (value: number) =>
                              Helper.formatPrice(value),
                            textAlign: "right",
                          },
                          {
                            name: "status",
                            notCollapseOnMobile: true,
                            label: "Status",
                            customBodyRender: (value) => (
                              <div>
                                <span className="color-bad">
                                  {value === "requested" && "Angefragt"}
                                  {value === "open" && "Offen"}
                                  {value === "cancelled" && "Storniert"}
                                  {value === "canceled" && "Storniert"}
                                  {value === "rejected" && "Abgelehnt"}
                                </span>
                                <span className="color-good">
                                  {value === "done" && "Erledigt"}
                                  {value === "waiting" && "Wartet auf Kunde"}
                                </span>
                              </div>
                            ),
                            textAlign: "right",
                          },
                        ]}
                        data={this.state.complaints.map(
                          (complaint: CashbackComplaint) => {
                            return {
                              createdAt: complaint.createdAt,
                              shopName: complaint.shopName,
                              price: complaint.price,
                              status: complaint.status,
                              transactionDate: complaint.transactionDate,
                            };
                          }
                        )}
                      />
                    </Grid>

                    {this.state.hasClickouts === true && (
                      <Grid container>
                        <Grid
                          item
                          xs={12}
                          style={{ textAlign: "right", paddingTop: 10 }}
                        >
                          <Link to="/nachbuchungsanfrage">
                            <Button
                              color="primary"
                              variant="contained"
                              size="medium"
                            >
                              <FontAwesomeIcon
                                icon={faCommentAltLines}
                                style={{
                                  marginRight: 10,
                                }}
                              />
                              Neue Nachbuchungsanfrage
                            </Button>
                          </Link>
                        </Grid>
                        <Grid container spacing={4} style={{ marginTop: 50 }}>
                          <Grid item lg={4}>
                            <Headline type="h3">Offen</Headline>

                            <p>
                              Ihre Anfrage ist eingegangen und wird in Kürze
                              bearbeitet
                            </p>
                          </Grid>

                          <Grid item lg={4}>
                            <Headline type="h3">Angefragt</Headline>
                            <p>
                              Ihre Anfrage wurde weiterverarbeitet und muss noch
                              durch den Partner bestätigt werden.
                            </p>
                          </Grid>

                          <Grid item lg={4}>
                            <Headline type="h3">Wartet auf Kunde</Headline>

                            <p>
                              Für Ihre Anfrage werden weitere Informationen von
                              Ihnen benötigt.
                            </p>
                          </Grid>

                          <Grid item lg={4}>
                            <Headline type="h3">Storniert</Headline>
                            <p>
                              Ihre Anfrage wurde storniert. Eine Weiterleitung
                              der Anfrage zu den Partnern erfolgt nicht.
                            </p>
                          </Grid>
                          <Grid item lg={4}>
                            <Headline type="h3">Abgelehnt</Headline>
                            <p>
                              Ihre Nachbuchungsanfrage wurde leider vom Partner
                              abgelehnt und Sie erhalten keine Zahlung.
                            </p>
                          </Grid>
                          <Grid item lg={4}>
                            <Headline type="h3">Erledigt</Headline>
                            <p>
                              Ihre Nachbuchungsanfrage wurde durch den Partner
                              bestätigt und Ihnen wird das Cashback
                              gutgeschrieben.
                            </p>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}

                    {this.state.hasClickouts === false && (
                      <Grid
                        item
                        xs={12}
                        style={{ textAlign: "left", paddingTop: 30 }}
                      >
                        <Headline type="h3">
                          Nachbuchungsanfrage erstellen
                        </Headline>

                        <p>
                          Hier können Sie eine Nachbuchungsanfrage stellen,
                          sobald Sie das erste Mal einen Einkauf über das
                          OLBplus Portal getätigt haben.
                        </p>
                      </Grid>
                    )}
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6} md={12}>
                        <DashboardBox
                          title="Cashback Guthaben"
                          link="/transaktionen/uebersicht"
                          icon={faAngleRight}
                          placedOnSidebar
                        >
                          <div className="dataWrapper">
                            <div className="dataRow">
                              <div className="dataCell">
                                <p>bestätigt</p>
                              </div>

                              <div className="dataCell">
                                <p>
                                  {Helper.formatPrice(
                                    this.state.confirmedCashback || 0
                                  )}
                                </p>
                              </div>
                            </div>

                            <div className="dataRow">
                              <div className="dataCell">
                                <p>offen</p>
                              </div>

                              <div className="dataCell">
                                <p>
                                  {Helper.formatPrice(
                                    this.state.pendingCashback || 0
                                  )}
                                </p>
                              </div>
                            </div>
                          </div>
                        </DashboardBox>
                      </Grid>

                      <Grid item xs={12} sm={6} md={12}>
                        <DashboardBox
                          title="Auszahlungen"
                          link="/auszahlungen"
                          icon={faAngleRight}
                          placedOnSidebar
                        >
                          <div className="dataWrapper">
                            <div className="dataRow">
                              <div className="dataCell">
                                <p>ausgezahlt</p>
                              </div>

                              <div className="dataCell">
                                <p>
                                  {this.state.cashoutProfile &&
                                    Helper.formatPrice(
                                      this.state.cashoutProfile.paidOutCash +
                                        this.state.cashoutProfile
                                          .externalPaidOutCash || 0
                                    )}
                                </p>
                              </div>
                            </div>
                          </div>
                        </DashboardBox>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </InnerLayout>
          )}
        </div>
      </Layout>
    );
  }
}

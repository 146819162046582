import "./Travel.scss";

import { faAngleRight, faUserShield } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid } from "@material-ui/core";
import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

import Box from "../../components/Box/Box";
import Headline from "../../components/Headline/Headline";
import ImageSlider from "../../components/ImageSlider/ImageSlider";
import InnerLayout from "../../components/InnerLayout/InnerLayout";
import { Layout } from "../../components/Layout/Layout";
import { Loading } from "../../components/Loading";
import ShortlinkBox from "../../components/ShortlinkBox/ShortlinkBox";
import TravelTile from "../../components/TravelTile/TravelTile";
import { ContentElements } from "../../interfaces/ContentElements";
import Shop from "../../interfaces/shop";
import { User } from "../../interfaces/user";
import Auth from "../../services/auth";
import Config from "../../services/config";
import CashbackManager from "../../services/manager/CashbackManager";
import StateManager from "../../services/manager/StateManager";
import UserManager from "../../services/manager/UserManager";
import CmsManager from "./../../services/manager/CmsManager";
import Helper from "../../services/helper";

interface State {
  travelHighlights: Shop[];
  loading: boolean;
  content?: ContentElements;
  user?: User;
  sliderContent: string;
  travelId: string;
}

interface Props {
  history: any;
}

export default class Travel extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      travelHighlights: [],
      loading: true,
      sliderContent: "",
      travelId: "",
    };

    this.handleFavoriteChange = this.handleFavoriteChange.bind(this);
  }

  async componentDidMount() {
    StateManager.resetProductsFilter();
    StateManager.resetShopFilter();

    const travelId = Config.getTravelCategoryId();
    const travelHighlights: any = [];

    const content = await CmsManager.getPageContentsCleaned("reisen");

    this.setState({
      travelHighlights: travelHighlights.items,
      travelId,
      content,
      loading: false,
    });

    let sliderContent = "";

    if (Auth.isLoggedIn()) {
      const user = await UserManager.me();

      this.setState({
        user,
      });

      if (
        user.userInfo._aditoBasicContractType ===
        "d039df5d-e363-4bdd-a940-7bee28a688a2"
      ) {
        // SGirokonto
        sliderContent = content.reisesliders as string;
      } else if (
        user.userInfo._aditoBasicContractType ===
        "b703a1ff-7a24-40bc-809e-80eed136fa3d"
      ) {
        // MGirokonto
        sliderContent = content.reisesliderm as string;
      } else if (
        user.userInfo._aditoBasicContractType ===
        "d4a9d4ff-e084-46ec-9b7e-1dce252d3b91"
      ) {
        // Girokonto Start
        sliderContent = content.reisesliderstart as string;
      } else if (
        user.userInfo._aditoBasicContractType ===
        "bfcd240e-371d-4539-a8c9-11e740de802f"
      ) {
        // LGirokonto
        sliderContent = content.reisesliderl as string;
      } else if (
        user.userInfo._aditoBasicContractType ===
        "3d7ff9bd-860c-4346-9254-c13a980622e0"
      ) {
        // XLGirokonto
        sliderContent = content.reisesliderxl as string;
      } else if (
        user.userInfo._aditoBasicContractType ===
        "5a225adc-df14-4ff5-81a2-13dfa634e0ee"
      ) {
        // XLMGirokonto
        sliderContent = content.reisesliderxl as string;
      }
    } else {
      sliderContent = content.reiseslidernichteingeloggt as string;
    }

    this.setState({ sliderContent });
  }

  handleFavoriteChange(shop: Shop) {
    return async (isFavorite: boolean) => {
      // Update shop
      if (!isFavorite) {
        await CashbackManager.removeShopFavorite(shop.id);
      } else {
        await CashbackManager.setShopFavorite(shop.id);
      }

      // Update view
      const shops = this.state.travelHighlights;

      shops.forEach((s: Shop) => {
        if (s.id === shop.id) {
          s.isFavorite = isFavorite;
        }
      });

      this.setState({
        travelHighlights: shops,
      });
    };
  }

  render() {
    return (
      <Layout>
        <div className="Travel">
          <Helmet>
            <meta charSet="utf-8" />
            <title>Reise - OLBplus Vorteilsportal</title>
          </Helmet>
          {this.state.loading && (
            <InnerLayout>
              <Loading />
            </InnerLayout>
          )}

          {!this.state.loading && (
            <InnerLayout>
              {this.state.sliderContent && (
                <ImageSlider
                  images={[
                    {
                      src: "/assets/images/heroes/Reise.jpg",
                      contentBox: {
                        content: this.state.sliderContent,
                      },
                      showServiceTile: true,
                    },
                  ]}
                />
              )}

              <Box>
                <Grid container spacing={2}>
                  <Grid xs={12} md={4} item>
                    <ShortlinkBox
                      icon="OlbIconTravelCashback"
                      title="Die Cashback-Reisepartner"
                      link={{
                        pathname: "/cashback/shops",
                        state: {
                          filterState: {
                            page: 1,
                            filter: {},
                            categoryFilter: this.state.travelId,
                            orderBy: "popularity",
                            direction: "desc",
                          },
                        },
                      }}
                      buttonText="Zu den Reisepartnern"
                      style={{ paddingTop: 0 }}
                    >
                      <p>
                        Buchen Sie wie gewohnt dort, wo Sie immer buchen und
                        erhalten Sie bis zu 10% Cashback.
                      </p>
                    </ShortlinkBox>
                  </Grid>

                  <Grid xs={12} md={4} item>
                    <ShortlinkBox
                      icon="OlbIconTravelSearch"
                      title="OLBplus Reisesuchmaschine"
                      link="/reise/buchung"
                      buttonText="Jetzt Reise finden"
                      style={{ paddingTop: 0 }}
                    >
                      <p>
                        Finden Sie jetzt Ihre Traumreise mit einer Auswahl von
                        180 namhaften Reiseveranstaltern.
                      </p>
                    </ShortlinkBox>
                  </Grid>

                  <Grid xs={12} md={4} item>
                    <ShortlinkBox
                      icon={faUserShield}
                      title="OLBplus Versicherungsschutz"
                      onClick={(e: any) =>
                        Helper.handleClickoutHint(
                          {
                            type: "travelInsurance",
                            url:
                              "https://www.olb.de/privatkunden/konten-und-karten/bezahlen-mit-karten/mastercard-gold-visa-card-gold#e8dc012b-5417-4ab2-b6d8-967b2e199d19",
                          },
                          e,
                          this.props.history
                        )
                      }
                      link="reiseversicherung"
                      buttonText="Mehr Infos"
                      style={{ paddingTop: 0 }}
                    >
                      <p>
                        Sorglos die Welt entdecken: Mit Ihrer Mastercard Gold
                        oder World Elite haben Sie auf Auslandsreisen immer ein
                        umfangreiches Versicherungspaket in der Tasche.
                      </p>
                    </ShortlinkBox>
                  </Grid>
                </Grid>
              </Box>

              {this.state.content &&
                this.state.content.highlight1Title &&
                this.state.content.highlight2Title && (
                  <Box>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={6}>
                            <TravelTile
                              image={
                                this.state.content.highlight1Files[0] as any
                              }
                              title={
                                this.state.content.highlight1Title as string
                              }
                              price={
                                this.state.content.highlight1Subtitle as string
                              }
                            >
                              {this.state.content.highlight1}
                            </TravelTile>
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <TravelTile
                              image={
                                this.state.content.highlight2Files[0] as string
                              }
                              title={
                                this.state.content.highlight2Title as string
                              }
                              price={
                                this.state.content.highlight2Subtitle as string
                              }
                            >
                              {this.state.content.highlight2}
                            </TravelTile>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                )}

              {this.state.content && (
                <Box>
                  <Grid container spacing={2}>
                    {Object.keys(this.state.content).map(
                      (item: string, index) => {
                        if (
                          this.state.content &&
                          [
                            "buchensiewiegewohntTitle",
                            "ihreganzpersonlichereiseTitle",
                          ].includes(item)
                        ) {
                          return (
                            <Grid
                              item
                              xs={12}
                              lg={6}
                              key={index}
                              className={
                                item === "ihreganzpersonlichereiseTitle"
                                  ? "crossLinkBox"
                                  : ""
                              }
                            >
                              {item === "ihreganzpersonlichereiseTitle" && (
                                <img
                                  src="/assets/images/heroes/persoenliche-reise.jpg"
                                  alt=""
                                  style={{
                                    width: "100%",
                                    borderRadius: "40px",
                                  }}
                                />
                              )}

                              {item === "buchensiewiegewohntTitle" && (
                                <img
                                  src="/assets/images/heroes/buchen-wie-gewohnt.jpg"
                                  alt=""
                                  style={{
                                    width: "100%",
                                    borderRadius: "40px",
                                  }}
                                />
                              )}

                              <Headline type="h2">
                                {this.state.content[item]}
                              </Headline>

                              <p
                                dangerouslySetInnerHTML={{
                                  __html: this.state.content[
                                    item.slice(0, -5)
                                  ] as string,
                                }}
                              ></p>

                              {item === "ihreganzpersonlichereiseTitle" && (
                                <Link
                                  to={{
                                    pathname: "/reise/buchung",
                                  }}
                                  className="twoColorLink moreLink"
                                >
                                  <FontAwesomeIcon
                                    icon={faAngleRight}
                                    style={{
                                      marginRight: 10,
                                    }}
                                  />
                                  Mehr erfahren
                                </Link>
                              )}

                              {item === "buchensiewiegewohntTitle" && (
                                <Link
                                  to={{
                                    pathname: "/cashback/shops",
                                    state: {
                                      filterState: {
                                        page: 1,
                                        filter: {},
                                        categoryFilter: this.state.travelId,
                                        orderBy: "popularity",
                                        direction: "desc",
                                      },
                                    },
                                  }}
                                  className="twoColorLink moreLink"
                                >
                                  <FontAwesomeIcon
                                    icon={faAngleRight}
                                    style={{
                                      marginRight: 10,
                                    }}
                                  />
                                  Mehr erfahren
                                </Link>
                              )}
                            </Grid>
                          );
                        } else {
                          return "";
                        }
                      }
                    )}
                  </Grid>
                </Box>
              )}
            </InnerLayout>
          )}
        </div>
      </Layout>
    );
  }
}

import './Dashboard.scss';

import { faAngleRight } from '@fortawesome/pro-light-svg-icons';
import { Grid } from '@material-ui/core';
import React from 'react';
import { Helmet } from 'react-helmet';

import Backlink from '../../components/Backlink/Backlink';
import Box from '../../components/Box/Box';
import DashboardBox from '../../components/DashboardBox/DashboardBox';
import Headline from '../../components/Headline/Headline';
import InnerLayout from '../../components/InnerLayout/InnerLayout';
import { Layout } from '../../components/Layout/Layout';
import { Loading } from '../../components/Loading';
import ResponsiveTable from '../../components/ResponsiveTable/ResponsiveTable';
import { CashbackShoppingTransaction } from '../../interfaces/cashbackShoppingTransaction';
import { CashbackTravelTransaction } from '../../interfaces/cashbackTravelTransaction';
import { CashoutProfile } from '../../interfaces/cashoutProfile';
import Shop from '../../interfaces/shop';
import Helper from '../../services/helper';
import CashbackManager from '../../services/manager/CashbackManager';
import StateManager from '../../services/manager/StateManager';

interface Props {}

interface State {
  cashoutProfile?: CashoutProfile;
  openComplaints: number;
  onlineTransactions: CashbackShoppingTransaction[];
  onlineCashbackOpen: number;
  onlineCashbackConfirmed: number;
  onlineCashbackCancelled: number;
  travelTransactions: CashbackTravelTransaction[];
  travelCashbackOpen: number;
  travelCashbackConfirmed: number;
  travelCashbackCancelled: number;
  voucherTransactions: CashbackShoppingTransaction[];
  voucherCashbackOpen: number;
  voucherCashbackConfirmed: number;
  voucherCashbackCancelled: number;
  loading: boolean;
  favoriteShops: Shop[];
  numberOfBasketItems: number;
}

export default class TransactionsOverview extends React.Component<
  Props,
  State
> {
  constructor(props: Props) {
    super(props);

    this.state = {
      openComplaints: 0,
      onlineTransactions: [],
      onlineCashbackOpen: 0,
      onlineCashbackConfirmed: 0,
      onlineCashbackCancelled: 0,
      travelTransactions: [],
      travelCashbackOpen: 0,
      travelCashbackConfirmed: 0,
      travelCashbackCancelled: 0,
      voucherTransactions: [],
      voucherCashbackOpen: 0,
      voucherCashbackConfirmed: 0,
      voucherCashbackCancelled: 0,
      numberOfBasketItems: 0,
      favoriteShops: [],
      loading: true,
    };
  }

  async componentDidMount() {
    StateManager.resetProductsFilter();
    StateManager.resetShopFilter();

    const result = await Promise.all([
      CashbackManager.getCashoutProfile(),
      CashbackManager.getNumberOfComplaints(),
      CashbackManager.getOnlineTransactions(),
      CashbackManager.getTravelTransactions(),
      CashbackManager.getVoucherTransactions(),
    ]);

    const sums = await Promise.all([
      CashbackManager.getCashbackAmount(result[2].items),
      CashbackManager.getCashbackAmount(result[3].items),
      CashbackManager.getCashbackAmount(result[4].items),
    ]);

    this.setState({
      cashoutProfile: result[0],
      openComplaints: result[1],
      onlineTransactions: result[2].items,
      onlineCashbackOpen: sums[0].sumOpen,
      onlineCashbackConfirmed: sums[0].sumConfirmed,
      onlineCashbackCancelled: sums[0].sumCancelled,
      travelTransactions: result[3].items,
      travelCashbackOpen: sums[1].sumOpen,
      travelCashbackConfirmed: sums[1].sumConfirmed,
      travelCashbackCancelled: sums[1].sumCancelled,
      voucherTransactions: result[4].items,
      voucherCashbackOpen: sums[2].sumOpen,
      voucherCashbackConfirmed: sums[2].sumConfirmed,
      voucherCashbackCancelled: sums[2].sumCancelled,
      loading: false,
    });
  }

  render() {
    return (
      <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Transaktionsübersicht - OLBplus Vorteilsportal</title>
        </Helmet>
        <div>
          {this.state.loading && (
            <InnerLayout>
              <Loading />
            </InnerLayout>
          )}

          {!this.state.loading && (
            <InnerLayout>
              <Box>
                <Backlink label="Zurück zur Übersicht" to="/profil" />

                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Grid item xs={12}>
                      <h1>Ihre Transaktionen</h1>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} md={8}>
                    <Grid item xs={12}>
                      <ResponsiveTable
                        lastRowIsSumRow
                        columns={[
                          {
                            name: 'category',
                            label: '',
                            notCollapseOnMobile: true,
                            textAlign: 'left',
                          },
                          { name: 'open', label: 'Offen', textAlign: 'right' },
                          {
                            name: 'confirmed',
                            label: 'Bestätigt',
                            textAlign: 'right',
                          },
                          {
                            name: 'canceled',
                            label: 'Storniert',
                            textAlign: 'right',
                          },
                          {
                            name: 'action',
                            label: '',
                            customBodyRender: (value, entity) =>
                              value && (
                                <a href={'/transaktionen?type=' + value}>
                                  mehr Infos
                                </a>
                              ),
                            textAlign: 'left',
                          },
                        ]}
                        data={[
                          {
                            category: 'Online Cashback',
                            open: Helper.formatPrice(
                              this.state.onlineCashbackOpen
                            ),
                            confirmed: Helper.formatPrice(
                              this.state.onlineCashbackConfirmed
                            ),
                            canceled: Helper.formatPrice(
                              this.state.onlineCashbackCancelled
                            ),
                            action: 'cashback',
                          },
                          {
                            category: 'Reiserückvergütung',
                            open: Helper.formatPrice(
                              this.state.travelCashbackOpen
                            ),
                            confirmed: Helper.formatPrice(
                              this.state.travelCashbackConfirmed
                            ),
                            canceled: Helper.formatPrice(
                              this.state.travelCashbackCancelled
                            ),
                            action: 'travel',
                          },
                          {
                            category: 'Gutscheine',
                            open: Helper.formatPrice(
                              this.state.voucherCashbackOpen
                            ),
                            confirmed: Helper.formatPrice(
                              this.state.voucherCashbackConfirmed
                            ),
                            canceled: Helper.formatPrice(
                              this.state.voucherCashbackCancelled
                            ),
                            action: 'voucher',
                          },
                          {
                            category: '',
                            open: Helper.formatPrice(
                              this.state.onlineCashbackOpen +
                                this.state.travelCashbackOpen +
                                this.state.voucherCashbackOpen
                            ),
                            confirmed: Helper.formatPrice(
                              this.state.onlineCashbackConfirmed +
                                this.state.travelCashbackConfirmed +
                                this.state.voucherCashbackConfirmed
                            ),
                            canceled: Helper.formatPrice(
                              this.state.onlineCashbackCancelled +
                                this.state.travelCashbackCancelled +
                                this.state.voucherCashbackCancelled
                            ),
                            action: '',
                          },
                        ]}
                      />
                    </Grid>

                    <Grid container spacing={4} style={{ marginTop: 50 }}>
                      <Grid item lg={4}>
                        <Headline type="h3">Offen</Headline>

                        <p>
                          Ihr Einkauf wurde erfasst und muss noch durch den
                          Partner bestätigt werden.
                        </p>
                      </Grid>

                      <Grid item lg={4}>
                        <Headline type="h3">Bestätigt</Headline>

                        <p>
                          Ihr Einkauf wurde vom Partner bestätigt. Nach der
                          Zahlung des Partners an uns wird Ihnen das Cashback
                          schnellstmöglich ausgezahlt.
                        </p>
                      </Grid>

                      <Grid item lg={4}>
                        <Headline type="h3">Storniert</Headline>

                        <p>
                          Das Cashback wurde leider vom Partner abgelehnt und
                          Sie erhalten keine Zahlung.
                        </p>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6} md={12}>
                        <DashboardBox
                          title="Auszahlungen"
                          link="/auszahlungen"
                          icon={faAngleRight}
                          placedOnSidebar
                        >
                          <div className="dataWrapper">
                            <div className="dataRow">
                              <div className="dataCell">
                                <p>ausgezahlt</p>
                              </div>

                              <div className="dataCell">
                                <p style={{ fontWeight: 'bold' }}>
                                  {this.state.cashoutProfile &&
                                    Helper.formatPrice(
                                      this.state.cashoutProfile.paidOutCash +
                                        this.state.cashoutProfile
                                          .externalPaidOutCash || 0
                                    )}
                                </p>
                              </div>
                            </div>
                          </div>
                        </DashboardBox>
                      </Grid>

                      <Grid item xs={12} sm={6} md={12}>
                        <DashboardBox
                          title="Nachbuchungsanfragen"
                          link="/nachbuchungsanfragen"
                          icon={faAngleRight}
                          placedOnSidebar
                        >
                          <div className="dataWrapper">
                            <div className="dataRow">
                              <div className="dataCell">
                                <p>offene Anfragen</p>
                              </div>

                              <div className="dataCell">
                                <p style={{ fontWeight: 'bold' }}>
                                  {this.state.openComplaints || 0}
                                </p>
                              </div>
                            </div>
                          </div>
                        </DashboardBox>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </InnerLayout>
          )}
        </div>
      </Layout>
    );
  }
}

import { ClickoutHintData } from '../interfaces/ClickoutHintData';

export default class Helper {
  static formatNumber(value: number, decimals = 0) {
    value = parseFloat(value as any);
    return value
      .toFixed(decimals)
      .replace('.', ',')
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
  }

  static formatDate(date: any) {
    if (!date) return '';
    if (typeof date !== 'object') {
      if (date.indexOf('T') === -1) {
        date = date.replace(/-/g, '/');
      }
      date = new Date(date);
    }
    const prefix = (c: number) => (c < 10 ? `0${c}` : c);
    return `${prefix(date.getDate())}.${prefix(
      date.getMonth() + 1
    )}.${date.getFullYear()}`;
  }

  static formatTimestamp(timestamp: number) {
    return new Date(timestamp * 1000).toLocaleDateString('de-DE');
  }

  static dateToString(date?: Date | null) {
    if (!date) return undefined;
    return `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${(
      '0' + date.getDate()
    ).slice(-2)}`;
  }

  static formatPrice(value: number, currency?: string) {
    const price = value.toLocaleString('de-DE', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    if (!currency || ['EUR', 'eur', 'euro', 'Euro'].indexOf(currency) > -1)
      currency = 'EUR';

    return `${price} ${currency}`;
  }

  static canViewPage(isLoggedIn: boolean, route: string, accountType: string) {
    const blacklist: any = {
      // SGirokonto
      // 'd039df5d-e363-4bdd-a940-7bee28a688a2': ['Tickets'],
      'd039df5d-e363-4bdd-a940-7bee28a688a2': [],
      // MGirokonto
      'b703a1ff-7a24-40bc-809e-80eed136fa3d': [],
      // Girokonto Start
      'd4a9d4ff-e084-46ec-9b7e-1dce252d3b91': [],
      // LGirokonto
      'bfcd240e-371d-4539-a8c9-11e740de802f': [],
      // XLGirokonto
      '3d7ff9bd-860c-4346-9254-c13a980622e0': [],
      // XLMGirokonto
      '5a225adc-df14-4ff5-81a2-13dfa634e0ee': [],
    };

    if (!Object.keys(blacklist).includes(accountType)) {
      return true;
    }

    return isLoggedIn ? blacklist[accountType].indexOf(route) === -1 : true;
  }

  static canViewRoute(isLoggedIn: boolean, route: string, accountType: string) {
    const blacklist: any = {
      // SGirokonto
      'd039df5d-e363-4bdd-a940-7bee28a688a2': ['/tickets'],
      // MGirokonto
      'b703a1ff-7a24-40bc-809e-80eed136fa3d': [],
      // Girokonto Start
      'd4a9d4ff-e084-46ec-9b7e-1dce252d3b91': [],
      // LGirokonto
      'bfcd240e-371d-4539-a8c9-11e740de802f': [],
      // XLGirokonto
      '3d7ff9bd-860c-4346-9254-c13a980622e0': [],
      // XLMGirokonto
      '5a225adc-df14-4ff5-81a2-13dfa634e0ee': [],
    };

    if (!Object.keys(blacklist).includes(accountType)) {
      return true;
    }

    return isLoggedIn
      ? blacklist[accountType] && blacklist[accountType].indexOf(route) === -1
      : true;
  }

  static hasSecurityService(service: string, accountType: string) {
    const blacklist: any = {
      // SGirokonto
      'd039df5d-e363-4bdd-a940-7bee28a688a2': [
        'kartenunddokumentenschutz',
        'notfallbargeld',
        'turoffnungsservice',
      ],
      // MGirokonto
      'b703a1ff-7a24-40bc-809e-80eed136fa3d': [
        'kartenunddokumentenschutz',
        'notfallbargeld',
        'turoffnungsservice',
      ],
      // Girokonto Start
      'd4a9d4ff-e084-46ec-9b7e-1dce252d3b91': [
        'kartenunddokumentenschutz',
        'notfallbargeld',
        'turoffnungsservice',
      ],
      // LGirokonto
      'bfcd240e-371d-4539-a8c9-11e740de802f': [
        'kartenunddokumentenschutz',
        'notfallbargeld',
      ],
      // XLGirokonto
      '3d7ff9bd-860c-4346-9254-c13a980622e0': [],
      // XLMGirokonto
      '5a225adc-df14-4ff5-81a2-13dfa634e0ee': [],
    };

    return (
      blacklist[accountType] && blacklist[accountType].indexOf(service) === -1
    );
  }

  static clickHandler(event: any, history: any) {
    let el = event.target;

    while (el && el !== event.currentTarget && el.tagName !== 'A') {
      el = el.parentNode;
    }

    if (el && el.tagName === 'A') {
      if (el.target && el.target === '_blank') {
        event.preventDefault();

        this.handleClickoutHint({ url: el.href }, event, history);
      } else {
        return;
      }
    }
  }

  static handleClickoutHint(data: ClickoutHintData, e: any, history: any) {
    e.preventDefault();

    history.push({
      pathname: '/clickout',
      state: data,
    });
  }
}

import './Dashboard.scss';

import DateFnsUtils from '@date-io/date-fns';
import { faAngleRight } from '@fortawesome/pro-regular-svg-icons';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextareaAutosize,
  TextField,
} from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import _ from 'lodash';
import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { Link, Redirect } from 'react-router-dom';

import Backlink from '../../components/Backlink/Backlink';
import Box from '../../components/Box/Box';
import DashboardBox from '../../components/DashboardBox/DashboardBox';
import Headline from '../../components/Headline/Headline';
import InnerLayout from '../../components/InnerLayout/InnerLayout';
import { Layout } from '../../components/Layout/Layout';
import { Loading } from '../../components/Loading';
import Shop from '../../interfaces/shop';
import { User } from '../../interfaces/user';
import { Alert } from '../../services/alert';
import Auth from '../../services/auth';
import Helper from '../../services/helper';
import CashbackManager from '../../services/manager/CashbackManager';
import StateManager from '../../services/manager/StateManager';
import UserManager from '../../services/manager/UserManager';

interface Props {}

interface State {
  shops: Shop[];
  loading: boolean;
  user?: User;
  sent: boolean;
  privacy: boolean;
  transactionDate: Date;
  name: string;
  mail: string;
  shopId: string;
  cashBackRate: string;
  cashBackRateType: string;
  productName: string;
  price: number;
  orderNumber: string;
  billNumber: string;
  customerNumber: string;
  comment: string;
  errors: any;

  confirmedCashback?: any;
  pendingCashback?: any;
  cashoutProfile?: any;
  cleanCashbackRates?: any;
}

export default class CreateComplaint extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      shops: [],
      loading: true,
      sent: false,
      privacy: false,
      transactionDate: new Date(),
      name: "",
      mail: "",
      shopId: "",
      cashBackRate: "",
      cashBackRateType: "",
      productName: "",
      price: 0,
      orderNumber: "",
      billNumber: "",
      customerNumber: "",
      comment: "",
      errors: {},
    };

    this.handleChange = this.handleChange.bind(this);
    this.getFieldValue = this.getFieldValue.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async componentDidMount() {
    StateManager.resetProductsFilter();
    StateManager.resetShopFilter();

    const shops = await CashbackManager.findLastClickoutShops();
    const cleanShops = _.uniqBy(shops, "id");
    const user = await UserManager.me();
    const confirmedCashback = await CashbackManager.getCashbackAmountSum(
      "confirmed"
    );
    const pendingCashback = await CashbackManager.getCashbackAmountSum("open");
    const cashoutProfile = await CashbackManager.getCashoutProfile();

    this.setState({
      shops: cleanShops,
      name: `${user.userInfo.firstName} ${user.userInfo.lastName}`,
      mail: user.email,
      shopId: "",
      user,
      loading: false,
      confirmedCashback,
      pendingCashback,
      cashoutProfile,
    });
  }

  isValid() {
    let errorsCount = 0;
    const errors: any = {};

    if (!this.state.privacy) {
      errors.privacy = "Pflichtfeld";
      errorsCount++;
    } else {
      errors.privacy = "";
    }

    if (!this.state.cashBackRate) {
      errors.cashBackRate = "Pflichtfeld";
      errorsCount++;
    } else {
      errors.cashBackRate = "";
    }

    this.setState({ errors });
    return errorsCount === 0;
  }

  async handleSubmit() {
    if (!this.isValid()) return;

    this.setState({
      loading: true,
    });

    const [cashBackRate, cashBackRateType] = this.state.cashBackRate.split("|");

    await CashbackManager.createComplaint({
      shopId: this.state.shopId,
      fkn: Auth.getFKN(),
      name: this.state.name,
      mail: this.state.user ? this.state.user.email : this.state.mail,
      productName: this.state.productName,
      orderNumber: this.state.orderNumber,
      customerNumber: this.state.customerNumber,
      price: this.state.price,
      billNumber: this.state.billNumber,
      transactionDate: Helper.dateToString(this.state.transactionDate),
      comment: this.state.comment,
      cashBackRate,
      cashBackRateType: cashBackRateType === "%" ? "perc" : "eur",
    });

    Alert.success(
      "Vielen Dank für Ihre Anfrage!",
      "Ihre Nachbuchungsanfrage wird so schnell wie möglich bearbeitet."
    );

    this.setState({
      sent: true,
      loading: false,
    });
  }

  handleChange(field: string) {
    return (e: any) => {
      this.setState(
        {
          [field]:
            field === "transactionDate"
              ? e
              : field === "privacy"
              ? e.target.checked
              : e.target.value,
          ...(field === "shopId" ? { cashBackRate: "" } : {}),
        } as any,
        () => {
          if (field === "shopId") {
            const tempCashbackRates = this.state.shops.find(
              (shop) => shop.id === this.state.shopId
            )?.cashbackRates;

            if (tempCashbackRates) {
              const cleanCashbackRates = _.sortBy(
                _.uniqBy(tempCashbackRates, "amount"),
                ["type", "amount"]
              );

              this.setState({
                cleanCashbackRates,
              });
            }

            this.forceUpdate();
          }
        }
      );
    };
  }

  getFieldValue(field: string): string {
    const state: any = this.state as any;
    return state[field];
  }

  render() {
    if (this.state.sent) return <Redirect to="/nachbuchungsanfragen" />;

    return (
      <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Neue Nachbuchungsanfrage - OLBplus Vorteilsportal</title>
        </Helmet>
        <div>
          {this.state.loading && (
            <InnerLayout>
              <Loading />
            </InnerLayout>
          )}

          {!this.state.loading && (
            <InnerLayout>
              <Box>
                <Backlink label="Zurück zur Übersicht" />
                <Grid container spacing={4}>
                  <Grid item xs={12} md={12}>
                    <Headline type="h1">Neue Nachbuchung&shy;sanfrage</Headline>

                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={8}>
                        <Grid container spacing={2}>
                          {[
                            { label: "Name", field: "name" },
                            {
                              label: "Shop",
                              field: "shopId",
                              component: (
                                <FormControl variant="filled">
                                  <InputLabel>Shop</InputLabel>
                                  <Select
                                    value={this.state.shopId}
                                    onChange={this.handleChange("shopId")}
                                  >
                                    {this.state.shops.map((s, key) => (
                                      <MenuItem key={key} value={s.id}>
                                        {s.name}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              ),
                            },
                            {
                              label: "Gekauft am",
                              field: "transactionDate",
                              component: (
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                  <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    format="dd.MM.yyyy"
                                    margin="normal"
                                    label="Gekauft am"
                                    value={this.state.transactionDate}
                                    onChange={this.handleChange(
                                      "transactionDate"
                                    )}
                                    style={{ margin: 0 }}
                                    inputVariant={"filled"}
                                    KeyboardButtonProps={{
                                      "aria-label": "change date",
                                    }}
                                    invalidDateMessage="Falsches Format. Bitte Datum wie folgt eingeben: TT.MM.JJJJ"
                                  />
                                </MuiPickersUtilsProvider>
                              ),
                            },
                            { label: "Produkt", field: "productName" },
                            {
                              label: "Bestellnummer",
                              field: "orderNumber",
                            },
                            {
                              label: "Kundennummer",
                              field: "customerNumber",
                            },
                            { label: "Preis", field: "price" },
                            {
                              label: "Rechnungsnummer",
                              field: "billNumber",
                            },
                            {
                              label: "Erwartete/Verwendete Cashback-Rate",
                              field: "cashBackRate",
                              component: (
                                <Fragment>
                                  <FormControl variant="filled">
                                    <InputLabel>
                                      Erwartete/Verwendete Cashback-Rate
                                    </InputLabel>
                                    <Select
                                      value={this.state.cashBackRate}
                                      onChange={this.handleChange(
                                        "cashBackRate"
                                      )}
                                    >
                                      {this.state.cleanCashbackRates &&
                                        this.state.cleanCashbackRates.map(
                                          (cashbackRate: any) => (
                                            <MenuItem
                                              value={
                                                cashbackRate.amount +
                                                "|" +
                                                cashbackRate.type
                                              }
                                            >
                                              {Helper.formatNumber(
                                                cashbackRate.amount,
                                                2
                                              ) +
                                                " " +
                                                cashbackRate.type}
                                            </MenuItem>
                                          )
                                        )}
                                    </Select>
                                  </FormControl>
                                  {this.state.errors.cashBackRate && (
                                    <small className="formError">
                                      {this.state.errors.cashBackRate}
                                    </small>
                                  )}
                                </Fragment>
                              ),
                            },
                            {
                              label: "Benutzte E-Mail Adresse",
                              field: "mail",
                            },
                            {
                              label: "Kommentar",
                              field: "comment",
                              component: (
                                <Fragment>
                                  <TextareaAutosize
                                    aria-label="empty textarea"
                                    placeholder="Kommentar"
                                  />
                                </Fragment>
                              ),
                            },
                          ].map(({ label, field, component }, key) => (
                            <Grid item xs={12} key={key}>
                              {component}
                              {!component && (
                                <Fragment>
                                  <TextField
                                    label={label}
                                    variant="outlined"
                                    autoComplete={"family-name"}
                                    value={this.getFieldValue(field)}
                                    onChange={this.handleChange(field)}
                                  />
                                  {this.state.errors[field] && (
                                    <small className="formError">
                                      {this.state.errors[field]}
                                    </small>
                                  )}
                                </Fragment>
                              )}
                            </Grid>
                          ))}
                        </Grid>

                        <br />

                        <FormControl>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={this.state.privacy}
                                onChange={this.handleChange("privacy")}
                                value="true"
                                color="primary"
                              />
                            }
                            label={
                              <p>
                                Ich habe die{" "}
                                <Link to="datenschutz" target="_blank">
                                  Datenschutzinformationen
                                </Link>{" "}
                                gelesen und akzeptiere diese.
                              </p>
                            }
                          />
                        </FormControl>
                        {this.state.errors.privacy && (
                          <small className="formError">
                            {this.state.errors.privacy}
                          </small>
                        )}

                        <Grid
                          item
                          xs={12}
                          lg={12}
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "flex-end",
                          }}
                        >
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={this.handleSubmit}
                          >
                            Absenden
                          </Button>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={12}>
                            <DashboardBox
                              title="Cashback Guthaben"
                              link="/transaktionen/uebersicht"
                              icon={faAngleRight}
                              placedOnSidebar
                            >
                              <div className="dataWrapper">
                                <div className="dataRow">
                                  <div className="dataCell">
                                    <p>bestätigt</p>
                                  </div>

                                  <div className="dataCell">
                                    <p style={{ fontWeight: "bold" }}>
                                      {Helper.formatPrice(
                                        this.state.confirmedCashback || 0
                                      )}
                                    </p>
                                  </div>
                                </div>

                                <div className="dataRow">
                                  <div className="dataCell">
                                    <p>offen</p>
                                  </div>

                                  <div className="dataCell">
                                    <p style={{ fontWeight: "bold" }}>
                                      {Helper.formatPrice(
                                        this.state.pendingCashback || 0
                                      )}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </DashboardBox>
                          </Grid>

                          <Grid item xs={12} md={12}>
                            <DashboardBox
                              title="Auszahlungen"
                              link="/auszahlungen"
                              icon={faAngleRight}
                              placedOnSidebar
                            >
                              <div className="dataWrapper">
                                <div className="dataRow">
                                  <div className="dataCell">
                                    <p>ausgezahlt</p>
                                  </div>

                                  <div className="dataCell">
                                    <p style={{ fontWeight: "bold" }}>
                                      {this.state.cashoutProfile &&
                                        Helper.formatPrice(
                                          this.state.cashoutProfile
                                            .paidOutCash +
                                            this.state.cashoutProfile
                                              .externalPaidOutCash || 0
                                        )}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </DashboardBox>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>

                    <br />
                  </Grid>
                </Grid>
              </Box>
            </InnerLayout>
          )}
        </div>
      </Layout>
    );
  }
}

import "./Cashback.scss";

import { faPlaneDeparture } from "@fortawesome/pro-light-svg-icons";
import { Button, Grid, MenuItem, Select } from "@material-ui/core";
import _ from "lodash";
import queryString from "query-string";
import * as React from "react";
import { Fragment } from "react";
import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";

import Box from "../../components/Box/Box";
import CashbackFilterBox from "../../components/CashbackFilterBox/CashbackFilterBox";
import Headline from "../../components/Headline/Headline";
import ImageSlider from "../../components/ImageSlider/ImageSlider";
import InnerLayout from "../../components/InnerLayout/InnerLayout";
import { Layout } from "../../components/Layout/Layout";
import { Loading } from "../../components/Loading";
import Pagination from "../../components/Pagination/Pagination";
import ShopTile from "../../components/ShopTile/ShopTile";
import ShortlinkBox from "../../components/ShortlinkBox/ShortlinkBox";
import { CashbackCategory } from "../../interfaces/cashbackCategory";
import Product from "../../interfaces/product";
import SearchShop from "../../interfaces/searchShop";
import Shop from "../../interfaces/shop";
import SpecialInterface from "../../interfaces/special";
import { User } from "../../interfaces/user";
import Auth from "../../services/auth";
import Helper from "../../services/helper";
import CashbackManager from "../../services/manager/CashbackManager";
import StateManager from "../../services/manager/StateManager";
import UserManager from "../../services/manager/UserManager";
import CmsManager from "../../services/manager/CmsManager";
import { ContentElements } from "../../interfaces/ContentElements";

interface Props {
  match: any;
  location: any;
  history: any;
}

interface State {
  mode: string;
  query: string;
  shops: Shop[];
  products: Product[];
  productShops: SearchShop[];
  loading: boolean;
  page: number;
  numberOfPages: number;
  filters: string[];
  order: string;
  productOrder: string;
  shopsFilter: string[];
  priceRange: [number, number];
  priceRangeFilter: [number, number];
  categories: CashbackCategory[];
  categoryFilter: number;
  specials: SpecialInterface[];
  favorites: boolean;
  filterState: any;
  user?: User;
  content?: ContentElements;
}

const quickFilters: any = [
  { id: "favorites", label: "Meine Favoriten", unique: false },
  { id: "vouchers", label: "Mit Gutschein", unique: false },
  { id: "cashbackPercent", label: "Cashback in %", unique: true },
  { id: "cashbackAmount", label: "Cashback in €", unique: true },
];

export default class CashbackShops extends React.Component<Props, State> {
  public startAndEndDate = false;
  constructor(props: Props) {
    super(props);

    this.state = {
      mode: "shops",
      query: "",
      shops: [],
      products: [],
      productShops: [],
      loading: true,
      page: 1,
      numberOfPages: 0,
      filters: [],
      order: "popularity_desc",
      shopsFilter: [],
      priceRange: [0, 0],
      priceRangeFilter: [0, 0],
      productOrder: "priority",
      categories: [],
      categoryFilter: 0,
      specials: [],
      favorites: false,
      filterState: {},
    };

    this.handleChangeMode = this.handleChangeMode.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeOrder = this.handleChangeOrder.bind(this);
    this.handleToggleFilter = this.handleToggleFilter.bind(this);
    this.handleChangeShopFilter = this.handleChangeShopFilter.bind(this);
    this.handleChangePriceRangeFilter = this.handleChangePriceRangeFilter.bind(
      this
    );
    this.handleFavoriteChange = this.handleFavoriteChange.bind(this);
    this.handleChangeQuery = this.handleChangeQuery.bind(this);
    this.handleChangeCategoryFilter = this.handleChangeCategoryFilter.bind(
      this
    );
    this.handleRemoveFilter = this.handleRemoveFilter.bind(this);
  }

  handleChangePriceRangeFilter(event: any, value: any) {
    this.setState({
      priceRangeFilter: value,
    });
  }

  handleChangeShopFilter(event: any) {
    this.setState(
      {
        shopsFilter: event.target.value,
        page: 1,
      },
      () => {
        this.load();
      }
    );
  }

  handleChangeCategoryFilter(event: any) {
    this.setState(
      {
        categoryFilter: event.target.value,
        page: 1,
      },
      () => {
        this.load();
      }
    );
  }

  handleChangeOrder(event: any) {
    this.setState(
      {
        order: event.target.value,
        page: 1,
      },
      () => {
        this.load();
      }
    );
  }

  handleFavoriteChange(shop: Shop) {
    return async (isFavorite: boolean) => {
      // Update shop
      if (!isFavorite) {
        await CashbackManager.removeShopFavorite(shop.id);
      } else {
        await CashbackManager.setShopFavorite(shop.id);
      }

      // Update view
      const shops = this.state.shops;
      shops.forEach((s: Shop) => {
        if (s.id === shop.id) {
          s.isFavorite = isFavorite;
        }
      });

      this.setState({
        shops,
      });
    };
  }

  handleToggleFilter(filter: string, unique: boolean) {
    return () => {
      const filters = this.state.filters;

      const index = filters.indexOf(filter);

      if (index !== -1) {
        filters.splice(index, 1);
      } else {
        if (unique) {
          _.filter(quickFilters, {
            unique: true,
          }).map((uniqueFilter) => {
            const uniqueFilterIndex = filters.indexOf(uniqueFilter.id);

            if (uniqueFilterIndex !== -1) {
              filters.splice(uniqueFilterIndex, 1);
            }
          });
        }

        filters.push(filter);
      }

      this.setState(
        {
          filters,
        },
        () => {
          this.load();
        }
      );
    };
  }

  handleRemoveFilter() {
    this.setState(
      {
        filters: [],
        query: "",
        order: "popularity_desc",
        categoryFilter: 0,
      },
      () => {
        this.load();
      }
    );
  }

  handleChangeMode(mode: string) {
    return () => {
      this.setState(
        {
          mode,
          page: 1,
        },
        () => {
          this.load();
        }
      );
    };
  }

  handleChangePage(page: number) {
    this.setState(
      {
        page,
        loading: true,
      },
      () => {
        this.load();
      }
    );

    window.scrollTo({
      top: 0,
    });
  }

  async componentDidMount() {
    if (Auth.isLoggedIn()) {
      const user = await UserManager.me();

      this.setState({
        user,
      });
    }

    const [categories, specials, content] = await Promise.all([
      CashbackManager.findCategories(),
      CashbackManager.findSpecials(),
      CmsManager.getPageContentsCleaned("shops-produkte"),
    ]);

    this.setState({
      categories: categories.items,
      specials,
      content,
    });
    const params = queryString.parse(this.props.location.search) as any;
    const newState: any = {};

    if (params.q) {
      newState.query = params.q;
    }

    let oldState: any = StateManager.getState("cashback");

    if (this.props.location?.state?.filterState) {
      oldState = this.props.location.state.filterState;
      this.props.location.state.filterState = null;
    }

    if (oldState) {
      if (oldState.query) newState.query = oldState.query;
      if (oldState.page) newState.page = oldState.page;
      if (oldState.order) newState.order = oldState.order;
      if (oldState.filters) newState.filters = oldState.filters;
      if (oldState.categoryFilter)
        newState.categoryFilter = oldState.categoryFilter;
      if (oldState.mode) newState.mode = oldState.mode;
      if (oldState.shopsFilter) newState.shopsFilter = oldState.shopsFilter;
      if (oldState.productOrder) newState.productOrder = oldState.productOrder;
      if (oldState.priceRangeFilter)
        newState.priceRangeFilter = oldState.priceRangeFilter;
    }

    this.setState(newState);

    this.load();
  }

  async loadOnSearch() {
    this.setState({ loading: true });

    let filterState = this.getFilterState();
    this.updateFilterState(filterState);

    const params = this.getSearchParams();
    const shops = await this.fetchShops(params);

    if (typeof shops.items === "object")
      shops.items = Object.values(shops.items);

    this.setState({
      products: [],
      shops: shops.items,
      loading: false,
      page: shops.currentPage,
      numberOfPages: shops.numberOfPages,
      mode: "shops",
    });
  }

  getFilterState() {
    let filterState = { ...this.state };
    const locationFilterState = this.props.location?.state?.filterState;
    if (locationFilterState) {
      filterState = {
        ...filterState,
        ...locationFilterState,
      };
      this.props.location.state.filterState = null;
    }
    return filterState;
  }

  updateFilterState(filterState: State) {
    this.setState({ filterState });
    StateManager.setState("cashback", filterState);
  }

  getSearchParams(): {
    page: number;
    filter: any;
    query?: string;
  } {
    const { page } = this.state;
    const filter = {};

    let query;
    if (this.state.query.trim().length >= 3) {
      query = this.state.query;
    }

    return { page, filter, query };
  }

  async fetchShops(params: { page: number; filter: any; query?: string }) {
    return await CashbackManager.findShops(params);
  }

  async load() {
    await this.setState({ loading: true });

    let filterState = {
      query: this.state.query,
      page: this.state.page,
      order: this.state.order,
      filters: this.state.filters,
      categoryFilter: this.state.categoryFilter,
      mode: this.state.mode,
      shopsFilter: this.state.shopsFilter,
      productOrder: this.state.productOrder,
      priceRangeFilter: this.state.priceRangeFilter,
      favorites: this.state.favorites,
    };

    if (this.props.location?.state?.filterState) {
      filterState = this.props.location.state.filterState;
      this.props.location.state.filterState = null;
    }

    await this.setState({
      filterState,
    });

    StateManager.setState("cashback", filterState);

    const params = {
      page: this.state.page,
      filter: {},
    } as any;

    if (this.state.query.trim().length >= 3) params.query = this.state.query;

    if (this.state.order.trim() !== "") {
      params.orderBy = this.state.order.split("_")[0];
      params.direction = this.state.order.split("_")[1];
    }

    if (this.state.filters.indexOf("favorites") > -1) params.favorites = true;

    if (this.state.filters.indexOf("vouchers") > -1) params.vouchers = true;

    if (this.state.filters.indexOf("cashbackPercent") > -1)
      params.cashbackType = encodeURIComponent("perc");

    if (this.state.filters.indexOf("cashbackAmount") > -1)
      params.cashbackType = "eur";

    if (this.state.categoryFilter !== 0) {
      params.category = this.state.categoryFilter;
    }

    const shops = await CashbackManager.findShops(params);

    if (typeof shops.items === "object")
      shops.items = Object.values(shops.items);

    this.setState({
      products: [],
      shops: shops.items,
      loading: false,
      page: shops.currentPage,
      numberOfPages: shops.numberOfPages,
      mode: "shops",
    });
  }

  handleChangeQuery(query: string) {
    this.setState({
      query,
    });
  }

  handleSearch() {
    this.setState(
      {
        page: 1,
      },
      () => {
        this.loadOnSearch();
      }
    );
  }

  renderSortingSelect() {
    const sortings = [
      { label: "Alphabetisch", value: "name_asc" },
      {
        label: "Aktuelle Einträge zu Beginn",
        value: "createdAt_desc",
      },
      {
        label: "Cashback aufsteigend",
        value: "maximumCashback_asc",
      },
      {
        label: "Cashback absteigend",
        value: "maximumCashback_desc",
      },
      { label: "Beliebte zu Beginn", value: "popularity_desc" },
    ];

    return (
      <Select
        className="rounded-select"
        value={this.state.order}
        onChange={this.handleChangeOrder}
      >
        {sortings.map((order, key) => (
          <MenuItem value={order.value} key={key}>
            {order.label}
          </MenuItem>
        ))}
      </Select>
    );
  }

  renderQuickFilter() {
    return (
      <Fragment>
        {quickFilters.map((filter: any, key: number) => (
          <Button
            key={key}
            color="secondary"
            variant={
              this.state.filters.indexOf(filter.id) > -1
                ? "contained"
                : "outlined"
            }
            style={{ marginBottom: 8 }}
            onClick={this.handleToggleFilter(filter.id, filter.unique)}
            className="filterButton"
          >
            {filter.label}
          </Button>
        ))}
      </Fragment>
    );
  }

  renderCategoryFilter() {
    if (this.state.categories && this.state.categories.length > 0) {
      return (
        <Select
          className="rounded-select"
          value={this.state.categoryFilter}
          onChange={this.handleChangeCategoryFilter}
        >
          <MenuItem value={0}>Kategorie</MenuItem>

          {this.state.categories.map(
            (category: CashbackCategory, key: number) => (
              <MenuItem key={key} value={category.id}>
                {category.name}
              </MenuItem>
            )
          )}
        </Select>
      );
    } else {
      return <Fragment></Fragment>;
    }
  }

  showStoerer = (): { text: string } | undefined => {
    const now = new Date();
    const to = new Date("2021-05-17T23:59:59");

    return now <= to && this.state.content && this.state.content.stoerer
      ? { text: this.state.content?.stoerer as string }
      : undefined;
  };

  render() {
    return (
      <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Cashback-Shops - OLBplus Vorteilsportal</title>
        </Helmet>

        <InnerLayout>
          {this.state.loading && <Loading />}

          {!this.state.loading && (
            <div>
              <ImageSlider
                images={[
                  {
                    src: "/assets/images/heroes/Online.jpg",
                    contentBox: {
                      stoerer: this.showStoerer(),
                      aboveHeadline: "OLBplus Cashback",
                      headline: "Cashback in über 1.300 Shops",
                      subline:
                        "Shoppen und gleichzeitig Geld sparen: Stöbern Sie rund um die Uhr in mehr als 1.300 Onlineshops und kaufen Sie immer mit Rabatt ein. Nach jedem Einkauf wird ein Teil der Einkaufssumme als Cashback auf Ihr Konto zurücküberwiesen.",
                    },
                  },
                ]}
              />

              <div className="shopTypeSwitchWrapper">
                <NavLink
                  to={"/cashback/shops"}
                  className="shopTypeSwitch"
                  activeClassName="active"
                >
                  <Button variant="contained" className="tabButton">
                    Nur Shops
                  </Button>
                </NavLink>

                <NavLink
                  to={"/cashback/produkte"}
                  className="shopTypeSwitch"
                  activeClassName="active"
                >
                  <Button variant="contained" className="tabButton">
                    Nur Produkte
                  </Button>
                </NavLink>
              </div>

              {this.state.mode === "shops" && (
                <CashbackFilterBox
                  title="Finden Sie Ihren Shop"
                  placeholder="Shopname"
                  buttonLabel="Finden"
                  onSubmit={this.handleSearch}
                  onResetFilter={this.handleRemoveFilter}
                  onChange={this.handleChangeQuery}
                  query={this.state.query}
                  categoryFilter={this.renderCategoryFilter()}
                  quickFilter={this.renderQuickFilter()}
                  sortingSelect={this.renderSortingSelect()}
                ></CashbackFilterBox>
              )}

              {this.state.shops.length === 0 &&
                this.state.products.length === 0 && (
                  <p
                    style={{
                      textAlign: "center",
                    }}
                  >
                    Leider konnten für diesen Suchbegriff keine Ergebnisse
                    gefunden werden.
                  </p>
                )}

              <Box alternativeColor>
                <Grid container spacing={2} className="shops-list">
                  <Grid item xs={12}>
                    <Headline type="h2">Alle Cashback-Shops</Headline>
                  </Grid>

                  {this.state.shops.map((shop: Shop, key) => (
                    <Grid item xs={6} md={3} key={key}>
                      <ShopTile
                        image={shop.logo}
                        buttonLabel="Zum Shop"
                        morePath={`/shop/${shop.id}`}
                        buttonLink={shop.link}
                        isFavorite={shop.isFavorite}
                        onFavoriteChange={this.handleFavoriteChange(shop)}
                        history={this.props.history}
                        shopId={shop.id}
                      >
                        {shop.maximumCashback && (
                          <Fragment>
                            Bis zu{" "}
                            <span>
                              {Helper.formatNumber(shop.maximumCashback, 2)}{" "}
                              {shop.maximumCashbackType}
                            </span>{" "}
                            bei {shop.name}
                          </Fragment>
                        )}
                      </ShopTile>
                    </Grid>
                  ))}
                </Grid>

                <Pagination
                  currentPage={this.state.page}
                  numberOfPages={this.state.numberOfPages}
                  onPageChange={this.handleChangePage}
                />
              </Box>

              <Box>
                <Grid container spacing={2}>
                  <Grid xs={12} sm={6} item>
                    <ShortlinkBox
                      icon="OlbIconCashbackRadar"
                      title="OLBplus Cashbackradar"
                      link="/cashbackradar"
                      buttonText="Mehr erfahren"
                    >
                      <p>Jetzt für Ihren Browser herunterladen.</p>
                    </ShortlinkBox>
                  </Grid>

                  <Grid xs={12} sm={6} item>
                    <ShortlinkBox
                      icon={faPlaneDeparture}
                      title="OLBplus Reise"
                      link="/reise/buchung"
                      buttonText="Jetzt Reise buchen"
                    >
                      <p>
                        Buchen Sie Ihre Traumreise mit einer Auswahl von über
                        180 namhaften Veranstaltern.
                      </p>
                    </ShortlinkBox>
                  </Grid>
                </Grid>
              </Box>
            </div>
          )}
        </InnerLayout>
      </Layout>
    );
  }
}

import React from "react";
import { Helmet } from "react-helmet";

import Box from "../../components/Box/Box";
import DownloadList from "../../components/DownloadList/DownloadList";
import Headline from "../../components/Headline/Headline";
import InnerLayout from "../../components/InnerLayout/InnerLayout";
import { Layout } from "../../components/Layout/Layout";
import { Loading } from "../../components/Loading";
import { ContentElements } from "../../interfaces/ContentElements";
import { DownloadLink } from "../../interfaces/downloadLink";
import CmsManager from "../../services/manager/CmsManager";
import StateManager from "../../services/manager/StateManager";

interface Props {}

interface State {
  loading: boolean;
  content?: ContentElements;
}

export default class DocumentsAndFormulars extends React.Component<
  Props,
  State
> {
  constructor(props: Props) {
    super(props);

    this.state = {
      loading: true,
    };
  }

  async componentDidMount() {
    StateManager.resetProductsFilter();
    StateManager.resetShopFilter();

    const content = await CmsManager.getPageContentsCleaned(
      "dokumente-und-formulare"
    );

    this.setState({
      loading: false,
      content,
    });
  }

  render() {
    return (
      <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Dokumente &amp; Formulare - OLBplus Vorteilsportal</title>
        </Helmet>
        <div className="DocumentsAndFormulars">
          {this.state.loading && (
            <InnerLayout>
              <Loading />
            </InnerLayout>
          )}

          {!this.state.loading && this.state.content && (
            <InnerLayout>
              <Box>
                <Headline type="h1">{this.state.content.pageTitle}</Headline>

                {this.state.content.pageDescription && (
                  <Headline type="h2" color="secondary">
                    <p
                      dangerouslySetInnerHTML={{
                        __html: this.state.content.pageDescription as string,
                      }}
                    ></p>
                  </Headline>
                )}
              </Box>

              {this.state.content.downloadlinksTitle &&
                this.state.content.downloadlinksLinks &&
                this.state.content.downloadlinksLinks.length > 0 && (
                  <Box>
                    <Headline type="h2">
                      {this.state.content.downloadlinksTitle}
                    </Headline>

                    <DownloadList
                      links={
                        this.state.content.downloadlinksLinks as DownloadLink[]
                      }
                    ></DownloadList>
                  </Box>
                )}
            </InnerLayout>
          )}
        </div>
      </Layout>
    );
  }
}

import "./Special.scss";

import { faPlaneDeparture } from "@fortawesome/pro-light-svg-icons";
import { Grid } from "@material-ui/core";
import React from "react";
import { Helmet } from "react-helmet";

import Box from "../../components/Box/Box";
import Headline from "../../components/Headline/Headline";
import ImageSlider from "../../components/ImageSlider/ImageSlider";
import InnerLayout from "../../components/InnerLayout/InnerLayout";
import { Layout } from "../../components/Layout/Layout";
import { Loading } from "../../components/Loading";
import ShortlinkBox from "../../components/ShortlinkBox/ShortlinkBox";
import SpecialTile from "../../components/SpecialTile/SpecialTile";
import { ContentElements } from "../../interfaces/ContentElements";
import SpecialInterface from "../../interfaces/special";
import Helper from "../../services/helper";
import CashbackManager from "../../services/manager/CashbackManager";
import CmsManager from "../../services/manager/CmsManager";
import StateManager from "../../services/manager/StateManager";

interface Props {
  history: any;
}

interface State {
  specials: SpecialInterface[];
  loading: boolean;
  content?: ContentElements;
}

export default class Special extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      specials: [],
      loading: true,
    };
  }

  async componentDidMount() {
    StateManager.resetProductsFilter();
    StateManager.resetShopFilter();

    const specials = await CashbackManager.findSpecials();
    const content = await CmsManager.getPageContentsCleaned(
      "cashback-highlights"
    );

    this.setState({
      specials,
      loading: false,
      content,
    });
  }

  render() {
    return (
      <Layout>
        <div className="Special">
          <Helmet>
            <meta charSet="utf-8" />
            <title>Cashback Highlights - OLBplus Vorteilsportal</title>
          </Helmet>
          {this.state.loading && (
            <InnerLayout>
              <Loading />
            </InnerLayout>
          )}

          {!this.state.loading && this.state.content && (
            <InnerLayout>
              <ImageSlider
                images={[
                  {
                    src: "/assets/images/heroes/Online.jpg",
                    contentBox: {
                      content: this.state.content.pageDescription as string,
                    },
                  },
                ]}
              />

              <Box alternativeColor style={{ marginTop: -10 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Headline type="h1" size="small">
                      Die Cashback-Highlights
                    </Headline>
                  </Grid>

                  {this.state.specials.map((special: SpecialInterface, key) => (
                    <Grid item xs={12} md={6} key={key}>
                      <SpecialTile
                        image={special.image}
                        title={special.title}
                        subTitle={special.subTitle}
                        info={
                          special.end
                            ? `Nur bis zum ${Helper.formatDate(special.end)}`
                            : undefined
                        }
                        shopId={special.id}
                        history={this.props.history}
                        buttonLabel="Zum Shop"
                        buttonLink={special.link}
                        priceActual={special.priceActual}
                        priceBefore={special.priceBefore}
                        specialText={special.text}
                      ></SpecialTile>
                    </Grid>
                  ))}
                </Grid>
              </Box>

              <Box>
                <Grid container spacing={2}>
                  <Grid xs={12} sm={6} item>
                    <ShortlinkBox
                      icon="OlbIconCashbackRadar"
                      title="OLBplus Cashbackradar"
                      link="/cashbackradar"
                      buttonText="Mehr erfahren"
                    >
                      <p>Jetzt für Ihren Browser herunterladen.</p>
                    </ShortlinkBox>
                  </Grid>

                  <Grid xs={12} sm={6} item>
                    <ShortlinkBox
                      icon={faPlaneDeparture}
                      title="OLBplus Reise"
                      link="/reise/buchung"
                      buttonText="Jetzt Reise buchen"
                    >
                      <p>
                        Buchen Sie Ihre Traumreise mit einer Auswahl von über
                        180 namhaften Veranstaltern.
                      </p>
                    </ShortlinkBox>
                  </Grid>
                </Grid>
              </Box>
            </InnerLayout>
          )}
        </div>
      </Layout>
    );
  }
}

import { API } from "../API";

export default class VoucherManager {
  static async submitVoucher(
    code: string,
    fkn: string = "",
    comment: string = ""
  ): Promise<{ code: string | number; message: string }> {
    try {
      const data: any = {};
      if (fkn) data.fkn = fkn;
      if (comment) data.comment = comment;
      return await API.patch(`vouchers/vouchers/${code}`, data);
    } catch (error) {
      console.log(`error in voucherService`, error);
      return error;
    }
  }
}

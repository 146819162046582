import './ShopTile.scss';

import { faAngleRight } from '@fortawesome/pro-light-svg-icons';
import { faStar } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';

import Auth from '../../services/auth';
import Helper from '../../services/helper';
import { AuthButton } from '../AuthButton';

interface Props {
  children?: any;
  image?: string;
  buttonLabel?: string;
  morePath?: string;
  buttonLink?: string;
  name?: string;
  displayName?: string;
  history: any;
  shopId: number | string;

  isFavorite?: boolean;
  onFavoriteChange?: (isFavorite: boolean) => void | Promise<void>;
}

export default function ShopTile(props: Props) {
  return (
    <div className="ShopTile">
      <div className="ShopTile-image-wrapper">
        {props.image && props.morePath && (
          <Link to={props.morePath} className="imageLink">
            <img className="ShopTile-image" src={props.image} alt="" />
          </Link>
        )}

        {props.image && !props.morePath && (
          <img className="ShopTile-image" src={props.image} alt="" />
        )}

        {!props.image && props.morePath && (
          <Link to={props.morePath}>
            <h3>{props.displayName || props.name}</h3>
          </Link>
        )}

        {!props.image && !props.morePath && (
          <h3>{props.displayName || props.name}</h3>
        )}
      </div>

      <div className="ShopTile-main">
        <div className="ShopTile-content">{props.children}</div>

        {props.morePath && (
          <Link className="moreLink" to={props.morePath}>
            Mehr Details
            <FontAwesomeIcon
              icon={faAngleRight}
              style={{
                marginLeft: 10,
              }}
            />
          </Link>
        )}

        {props.buttonLabel && props.buttonLink && (
          <AuthButton
            color="primary"
            variant="contained"
            size="medium"
            href={props.buttonLink}
            target="_blank"
            onClick={(e: any) =>
              Helper.handleClickoutHint(
                {
                  type: 'shop',
                  url: props.buttonLink,
                  shopId: props.shopId,
                },
                e,
                props.history
              )
            }
          >
            {props.buttonLabel}
          </AuthButton>
        )}

        {props.isFavorite !== undefined && Auth.isLoggedIn() && (
          <div className="ShopTile-star">
            {props.isFavorite && (
              <FontAwesomeIcon
                icon={faStar}
                onClick={() =>
                  props.onFavoriteChange
                    ? props.onFavoriteChange(!props.isFavorite)
                    : {}
                }
              />
            )}

            {props.isFavorite !== true && (
              <FontAwesomeIcon
                style={{ color: '#ccc' }}
                icon={faStar}
                onClick={() =>
                  props.onFavoriteChange
                    ? props.onFavoriteChange(!props.isFavorite)
                    : {}
                }
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
}

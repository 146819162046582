import "./SpecialTile.scss";

import { Grid } from "@material-ui/core";
import React from "react";

import Helper from "../../services/helper";
import { AuthButton } from "../AuthButton";
import Headline from "../Headline/Headline";

interface Props {
  children?: any;
  image?: string;

  title?: string;
  subTitle?: string;
  info?: string;
  shopId: number | string;

  buttonLabel?: string;
  buttonLink?: string;

  priceActual?: string;
  priceBefore?: string;
  specialText?: string;
  history: any;
}

export default function SpecialTile(props: Props) {
  const handleClick = (e: any) => {
    Helper.handleClickoutHint(
      {
        type: "shop",
        url: props.buttonLink,
        shopId: props.shopId,
      },
      e,
      props.history
    );
  };

  return (
    <div className="SpecialTile">
      {props.image && props.buttonLink && (
        <img
          src={props.image}
          alt=""
          style={{
            width: "100%",
            borderTopLeftRadius: "40px",
            borderTopRightRadius: "40px",
          }}
          onClick={handleClick}
        />
      )}

      <div className="SpecialTile-main">
        {props.title && (
          <Headline type="h1" color="primary" size="small">
            {props.title}
          </Headline>
        )}

        {props.priceActual && (
          <Headline
            type="h2"
            color="primary"
            size="small"
            classNames="SpecialTile-priceActual"
          >
            {props.priceActual} {props.specialText && "*"}
          </Headline>
        )}

        {props.priceBefore && (
          <Headline type="h2" size="small" classNames="SpecialTile-priceBefore">
            {props.priceBefore}
          </Headline>
        )}

        {props.subTitle && (
          <div className="SpecialTile-subTitle">{props.subTitle}</div>
        )}

        {props.specialText && (
          <div className="SpecialTile-content">* {props.specialText}</div>
        )}
      </div>

      <div className="SpecialTile-footer">
        <Grid container spacing={2}>
          <Grid item xs={6}>
            {props.info && <div className="SpecialTile-info">{props.info}</div>}
          </Grid>

          <Grid
            item
            xs={6}
            style={{
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "flex-end",
            }}
          >
            {props.buttonLabel && props.buttonLink && (
              <AuthButton
                color="primary"
                variant="contained"
                href={props.buttonLink ? props.buttonLink : ""}
                target="_blank"
                style={{ marginBottom: 8 }}
                onClick={handleClick}
              >
                {props.buttonLabel}
              </AuthButton>
            )}
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

import './CashbackReclamation.scss';

import { Grid, TextareaAutosize, TextField, Button } from '@material-ui/core';
import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';

import Headline from '../../components/Headline/Headline';
import InnerLayout from '../../components/InnerLayout/InnerLayout';
import { Layout } from '../../components/Layout/Layout';
import { Loading } from '../../components/Loading';
import { ContentElements } from '../../interfaces/ContentElements';
import StateManager from '../../services/manager/StateManager';
import Box from './../../components/Box/Box';
import CmsManager from './../../services/manager/CmsManager';
import { Alert } from '../../services/alert';
import validator from 'validator';
import CashbackManager from '../../services/manager/CashbackManager';
import UserManager from '../../services/manager/UserManager';

interface Props {
  history?: any;
}

interface State {
  loading: boolean;
  content?: ContentElements;
  email: string;
  sent: boolean;
  comment: string;
  errors: any;
}

export default class CashbackReclamation extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      loading: true,
      email: '',
      sent: false,
      comment: '',
      errors: {},
    };
  }

  async componentDidMount() {
    StateManager.resetProductsFilter();
    StateManager.resetShopFilter();

    const [content, user] = await Promise.all([
      CmsManager.getPageContentsCleaned('cashback-reclamation'),
      UserManager.me(),
    ]);

    this.setState({
      loading: false,
      email: user?.email || '',
      content,
    });

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  isValid() {
    let errorsCount = 0;
    const errors: any = {};

    const FIELDS_TO_VALIDATE: Array<keyof State> = ['comment'];

    const validateField = (field: keyof State) => {
      if (!this.state[field]) {
        errors[field] = 'Dies ist ein Pflichtfeld';
        errorsCount++;
      } else {
        errors[field] = '';
      }
    };

    FIELDS_TO_VALIDATE.forEach(validateField);

    if (!validator.isEmail(this.state.email)) {
      errors.email = 'Ungültige E-Mail-Adresse';
      errorsCount++;
    } else {
      errors.email = '';
    }

    this.setState({ errors });
    return errorsCount === 0;
  }

  handleChange(field: string) {
    return (e: any) => {
      this.setState({
        [field]: e.target.value,
      } as any);
    };
  }

  async handleSubmit() {
    if (!this.isValid()) return;

    this.setState({
      loading: true,
    });

    const result = await CashbackManager.createReclamation(
      this.props.history.location.state.shopId,
      {
        comment: this.state.comment,
        email: this.state.email,
      }
    );

    if (result && !result.code) {
      Alert.success(
        'Vielen Dank für Ihre Anfrage!',
        'Ihre Reklamation wird so schnell wie möglich bearbeitet.'
      );
    } else {
      Alert.error(
        'Es ist ein Fehler aufgetreten' +
          (result.code
            ? `.
          Fehlercode ${result.code}`
            : ''),
        'Bitte versuchen Sie es später noch einmal.'
      );
    }

    this.setState({
      sent: true,
      loading: false,
    });
  }

  render() {
    return (
      <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Cashback Reklamation - MLP Cashback</title>
        </Helmet>
        <div className="cashbackReclamation">
          {this.state.loading && <Loading />}
          {!this.state.loading && (
            <Fragment>
              <InnerLayout>
                <Box
                  style={{ paddingBottom: '10px', marginBottom: 0 }}
                  className="noVerticalPaddingOnMobile"
                >
                  <Headline type="h1" styling="thin">
                    <span
                      dangerouslySetInnerHTML={{
                        __html: this.state.content?.pageTitle as string,
                      }}
                    ></span>
                  </Headline>

                  <Grid container spacing={2}>
                    <Grid item xs={12} md={8}>
                      <div
                        className="page-description"
                        dangerouslySetInnerHTML={{
                          __html: this.state.content?.pageDescription as string,
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>

                <Box style={{ marginTop: 0, paddingTop: 0 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <h2 style={{ marginTop: 0 }}>
                        Betreff: {this.props.history.location.state.shopName}
                      </h2>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        label="E-Mail"
                        variant="outlined"
                        value={this.state.email}
                        name="email"
                        disabled
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextareaAutosize
                        aria-label="empty textarea"
                        placeholder="Nachricht"
                        name="comment"
                        value={this.state.comment}
                        onChange={this.handleChange('comment')}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={this.handleSubmit}
                        style={{ float: 'right' }}
                      >
                        Absenden
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </InnerLayout>
            </Fragment>
          )}
        </div>
      </Layout>
    );
  }
}

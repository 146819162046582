import "./Tickets.scss";

import { faPlaneDeparture } from "@fortawesome/pro-light-svg-icons";
import { Grid } from "@material-ui/core";
import React from "react";
import { Helmet } from "react-helmet";

import { AuthButton } from "../../components/AuthButton";
import Headline from "../../components/Headline/Headline";
import ImageSlider from "../../components/ImageSlider/ImageSlider";
import InnerLayout from "../../components/InnerLayout/InnerLayout";
import { Layout } from "../../components/Layout/Layout";
import { Loading } from "../../components/Loading";
import ShortlinkBox from "../../components/ShortlinkBox/ShortlinkBox";
import { ContentElements } from "../../interfaces/ContentElements";
import { User } from "../../interfaces/user";
import Auth from "../../services/auth";
import StateManager from "../../services/manager/StateManager";
import UserManager from "../../services/manager/UserManager";
import Box from "./../../components/Box/Box";
import CmsManager from "./../../services/manager/CmsManager";

interface State {
  content?: ContentElements;
  user?: User;
  loading: boolean;
}

interface Props {}

export default class TravelBookingSelection extends React.Component<
  Props,
  State
> {
  constructor(props: Props) {
    super(props);

    this.state = {
      loading: true,
    };
  }

  async componentDidMount() {
    StateManager.resetProductsFilter();
    StateManager.resetShopFilter();

    if (Auth.isLoggedIn()) {
      const user = await UserManager.me();

      this.setState({
        user,
      });

      const script = document.createElement("script");
      script.src = "https://www.eventim.de/obj/nucleus/partner/iframe.js";
      script.async = true;
      document.head.appendChild(script);
    }

    const content = await CmsManager.getPageContentsCleaned("tickets-1");

    this.setState({
      content,
      loading: false,
    });
  }

  hideFrameLoading() {
    const el = document.getElementById("iframe-loading");
    if (el) el.style.display = "none";

    (window as any).eventim.iframe();
  }

  render() {
    const fkn = Auth.getFKN();

    return (
      <Layout>
        <div className="Tickets">
          <Helmet>
            <meta charSet="utf-8" />
            <title>Tickets - OLBplus Vorteilsportal</title>
          </Helmet>
          {this.state.loading && (
            <InnerLayout>
              <Loading />
            </InnerLayout>
          )}

          {!this.state.loading && this.state.content && (
            <InnerLayout>
              <Grid className="onlyMobile">
                <Box>
                  {this.state.content.introTitle && (
                    <div>
                      <Headline type="h1">
                        {this.state.content.introTitle}
                      </Headline>

                      {this.state.content.intro && (
                        <div style={{ width: "80%" }}>
                          <Headline type="h2" color="secondary" styling="thin">
                            <p
                              dangerouslySetInnerHTML={{
                                __html: this.state.content.intro as string,
                              }}
                            ></p>
                          </Headline>
                        </div>
                      )}
                    </div>
                  )}
                </Box>
              </Grid>

              <ImageSlider
                images={[
                  {
                    src: "/assets/images/heroes/Tickets.jpg",
                    contentBox: {
                      content: this.state.content.pageDescription as string,
                    },
                  },
                ]}
              />

              <Grid className="notMobile">
                <Box>
                  {this.state.content.introTitle && (
                    <div>
                      <Headline type="h1">
                        {this.state.content.introTitle}
                      </Headline>

                      {this.state.content.intro && (
                        <div style={{ width: "80%" }}>
                          <Headline type="h2" color="secondary" styling="thin">
                            <p
                              dangerouslySetInnerHTML={{
                                __html: this.state.content.intro as string,
                              }}
                            ></p>
                          </Headline>
                        </div>
                      )}
                    </div>
                  )}
                </Box>
              </Grid>

              <Box alternativeColor>
                {Auth.isLoggedIn() && (
                  <div>
                    <div id="iframe-loading">
                      <Loading />
                    </div>

                    <iframe
                      src={`https://www.eventim.de/noapp/?affiliate=olb&referer_info=${fkn}`}
                      title="Eventim"
                      width="100%"
                      height="1350"
                      style={{
                        border: 0,
                      }}
                      onLoad={this.hideFrameLoading}
                    ></iframe>
                  </div>
                )}

                {!Auth.isLoggedIn() && (
                  <div style={{ position: "relative" }}>
                    <img
                      src="/assets/images/ticketsBlurred.png"
                      alt=""
                      style={{
                        width: "100%",
                      }}
                    />

                    <div className="loginOverlay">
                      <div className="loginRequired">
                        <Headline type="h2" color="white">
                          Bitte einloggen
                        </Headline>

                        <p style={{ color: "#ffffff" }}>
                          Bitte loggen Sie sich ein, damit Sie sich die besten
                          Ticketangebote sichern können. Anschließend haben Sie
                          die Möglichkeit das gewünschte Event auszuwählen und
                          zu buchen.
                        </p>

                        <AuthButton
                          color="primary"
                          variant="contained"
                          size="medium"
                          style={{
                            alignSelf: "flex-end",
                            marginTop: "2em",
                          }}
                        >
                          Login
                        </AuthButton>
                      </div>
                    </div>
                  </div>
                )}
              </Box>
              <Grid container spacing={2}>
                <Grid xs={12} sm={6} item>
                  <ShortlinkBox
                    icon="OlbIconCashbackRadar"
                    title="OLBplus Cashbackradar"
                    link="/cashbackradar"
                    buttonText="Mehr erfahren"
                  >
                    <p>
                      Jetzt Cashback sammeln und keine Rabatte mehr verpassen.
                    </p>
                  </ShortlinkBox>
                </Grid>

                <Grid xs={12} sm={6} item>
                  <ShortlinkBox
                    icon={faPlaneDeparture}
                    title="OLBplus Reise"
                    link="/reise/buchung"
                    buttonText="Jetzt Reise buchen"
                  >
                    <p>
                      Buchen Sie Ihre Traumreise mit einer Auswahl von über 180
                      namhaften Veranstaltern.
                    </p>
                  </ShortlinkBox>
                </Grid>
              </Grid>
            </InnerLayout>
          )}
        </div>
      </Layout>
    );
  }
}

import { Hidden } from '@material-ui/core';
import React from 'react';

import Box from '../components/Box/Box';
import Hero from '../components/Hero/Hero';
import InnerLayout from '../components/InnerLayout/InnerLayout';
import { Layout } from '../components/Layout/Layout';

interface Props {}

interface State {}

export default class UnsubscribeFromNewsletter extends React.Component<
  Props,
  State
> {
  render() {
    return (
      <Layout>
        <Hidden mdUp>
          <img
            className="img-responsive"
            style={{ width: "100%", height: "auto", marginTop: 99 }}
            src="/assets/images/heroes/faq.jpg"
            alt=""
          />
          <h1>Newsletterabmeldung</h1>
        </Hidden>

        <Hidden smDown>
          <Hero
            size="lg"
            imageSrc="/assets/images/heroes/faq.jpg"
            imagePositionY={-300}
          >
            <h1>Newsletterabmeldung</h1>
          </Hero>
        </Hidden>

        <InnerLayout>
          <Box>
            <h2>Newsletterabmeldung</h2>
            <p>Sie wurden erfolgreich von unserem Newsletter abgemeldet.</p>
          </Box>
        </InnerLayout>
      </Layout>
    );
  }
}

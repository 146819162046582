import * as React from 'react';

import Box from '../components/Box/Box';
import InnerLayout from '../components/InnerLayout/InnerLayout';
import { Layout } from '../components/Layout/Layout';

export class Error404 extends React.Component<{}, {}> {
  render() {
    return (
      <Layout title="Seite nicht gefunden">
        <div className="Registration">
          <InnerLayout>
            <Box>
              <h1>404</h1>
              <h2>Die Seite wurde nicht gefunden</h2>
              <p>Unter dieser Adresse wurde leider keine Seite gefunden.</p>
            </Box>
          </InnerLayout>
        </div>
      </Layout>
    );
  }
}

import { faAngleRight } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, Hidden } from '@material-ui/core';
import * as React from 'react';
import { Helmet } from 'react-helmet';

import { AuthButton } from '../../components/AuthButton';
import Backlink from '../../components/Backlink/Backlink';
import Box from '../../components/Box/Box';
import Headline from '../../components/Headline/Headline';
import InnerLayout from '../../components/InnerLayout/InnerLayout';
import { Layout } from '../../components/Layout/Layout';
import { Loading } from '../../components/Loading';
import ResponsiveTable from '../../components/ResponsiveTable/ResponsiveTable';
import Product from '../../interfaces/product';
import ProductOffer from '../../interfaces/productOffer';
import Auth from '../../services/auth';
import Helper from '../../services/helper';
import CashbackManager from '../../services/manager/CashbackManager';
import StateManager from '../../services/manager/StateManager';

interface Props {
  match: any;
  history: any;
}

interface State {
  product?: Product;
  offers: ProductOffer[];
  loading: boolean;
  currentFilter: any;
}

export default class ProductDetail extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      loading: true,
      offers: [],
      currentFilter: {},
    };
  }

  async componentDidMount() {
    const [currentFilter, product] = await Promise.all([
      StateManager.getState('pap'),
      CashbackManager.findProduct(this.props.match.params.id),
    ]);

    let offers: ProductOffer[] = [];
    if (product.unifiedProductId) {
      offers = await CashbackManager.findProductOffers(
        product.unifiedProductId
      );
    }

    if (offers.length === 0) {
      offers.push(product);
    }

    this.setState({
      product,
      offers,
      loading: false,
      currentFilter,
    });
  }

  render() {
    return (
      <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Produktansicht - OLBplus Vorteilsportal</title>
        </Helmet>
        {this.state.loading && (
          <InnerLayout>
            <Loading />
          </InnerLayout>
        )}

        {!this.state.loading && this.state.product && (
          <InnerLayout className="ProductDetail">
            <Box>
              <Backlink
                label="Zurück zur Übersicht"
                state={this.state.currentFilter}
              />
            </Box>

            <Grid container spacing={2}>
              <Grid item md={9}>
                <Box
                  style={{
                    height: '100%',
                  }}
                >
                  <Headline type="h1" size="small">
                    {this.state.product.name}
                  </Headline>

                  <p>{this.state.product?.description}</p>

                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <AuthButton
                      color="primary"
                      variant="contained"
                      size="medium"
                      href={this.state.product?.link}
                      target="_blank"
                      onClick={(e: any) =>
                        Helper.handleClickoutHint(
                          {
                            type: 'shop',
                            url: this.state?.product?.link,
                            shopId: this.state?.product?.shop?.id,
                          },
                          e,
                          this.props.history
                        )
                      }
                    >
                      zum Angebot
                      <FontAwesomeIcon
                        icon={faAngleRight}
                        style={{
                          marginLeft: 10,
                        }}
                      />
                    </AuthButton>

                    <div
                      style={{
                        textAlign: 'right',
                      }}
                    >
                      <div
                        style={{
                          marginBottom: 5,
                        }}
                      >
                        ab{' '}
                        <strong
                          className="color-primary"
                          style={{
                            fontSize: 24,
                          }}
                        >
                          {Helper.formatPrice(
                            this.state.product?.price / 100,
                            this.state.product.currency
                          )}
                        </strong>
                      </div>
                      gefunden auf{' '}
                      <strong>{this.state.product?.shop.name}</strong>
                    </div>
                  </div>
                </Box>
              </Grid>

              <Grid item md={3} style={{ width: '100%' }}>
                <Box
                  style={{
                    height: '100%',
                    width: '100%',
                  }}
                >
                  <img src={this.state.product?.imageUrl} width="100%" alt="" />
                </Box>
              </Grid>
            </Grid>

            <Box
              style={{
                marginTop: '1em',
              }}
            >
              <Headline type="h2">Angebote</Headline>

              <ResponsiveTable
                options={{
                  pagination: true,
                }}
                columns={[
                  {
                    name: 'brand',
                    label: 'Marke',
                    notCollapseOnMobile: true,
                  },
                  {
                    name: 'price',
                    label: 'Preis',
                    customBodyRender: (value: any) =>
                      isNaN(value) ? value : Helper.formatPrice(value, '€'),
                  },
                  {
                    name: 'shipping',
                    label: 'Versandkosten',
                    customBodyRender: (value: any) =>
                      value === 0
                        ? 'Kostenlos'
                        : Helper.formatPrice(value, '€'),
                  },
                  {
                    name: 'total',
                    label: 'Gesamtpreis',
                    notCollapseOnMobile: true,

                    customBodyRender: (value: any) =>
                      isNaN(value) ? value : Helper.formatPrice(value, '€'),
                  },
                  {
                    name: 'cashback',
                    label: 'Cashback',
                  },
                  {
                    name: 'shop',
                    label: 'Angebot bei',
                  },
                  {
                    name: 'btn',
                    label: ' ',
                    notCollapseOnMobile: true,
                  },
                ]}
                data={this.state.offers.map((offer: ProductOffer) => {
                  return {
                    brand: offer.brand,
                    price: offer.price / 100,
                    shipping: offer.shippingCosts
                      ? offer.shippingCosts / 100
                      : 0,
                    total: offer.shippingCosts
                      ? (offer.price + offer.shippingCosts) / 100
                      : offer.price / 100,
                    cashback: `bis zu ${Helper.formatNumber(
                      offer.shop.maximumCashback || 0,
                      2
                    )} ${offer.shop.maximumCashbackType}`,
                    shop: offer.shop.name,
                    btn: (
                      <div>
                        <Hidden smDown>
                          <AuthButton
                            color="primary"
                            variant="contained"
                            href={offer.link}
                            target="_blank"
                            onClick={(e: any) =>
                              Helper.handleClickoutHint(
                                {
                                  type: 'shop',
                                  url: this.state?.product?.link,
                                  shopId: this.state?.product?.shop?.id,
                                },
                                e,
                                this.props.history
                              )
                            }
                          >
                            Zum Angebot
                          </AuthButton>
                        </Hidden>
                        <Hidden mdUp>
                          {Auth.isLoggedIn() && (
                            <a
                              href={offer.link}
                              target="_blank"
                              rel="noopener noreferrer"
                              onClick={(e: any) =>
                                Helper.handleClickoutHint(
                                  {
                                    type: 'shop',
                                    url: offer.link,
                                    shopId: this.state?.product?.shop?.id,
                                  },
                                  e,
                                  this.props.history
                                )
                              }
                            >
                              zum Angebot
                            </a>
                          )}
                        </Hidden>
                      </div>
                    ),
                  };
                })}
              />
            </Box>
          </InnerLayout>
        )}
      </Layout>
    );
  }
}

import React, { CSSProperties } from "react";
import {
  Container,
  Grid,
  useMediaQuery,
  useTheme
} from "@material-ui/core";
import "./Hero.scss";

export default function Hero(props: {
  children?: any;
  imageSrc: string;
  imagePositionY?: number;
  size?: "sm" | "md" | "lg" | "home";
  rootComponent?: any;
}) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  const imgContainerStyle = {
    backgroundImage: `url(${props.imageSrc})`
  } as CSSProperties;

  if (props.imagePositionY && matches) {
    imgContainerStyle.backgroundPositionY = props.imagePositionY;
  }

  return (
    <div className={"hero " + (props.size || "md")}>
      <div className="image" style={imgContainerStyle} />
      <Container maxWidth="lg" className="heroContainer">
        <Grid container>
          <Grid item xs={12}>
            {props.children}
          </Grid>
        </Grid>
      </Container>
      {props.rootComponent}
    </div>
  );
}

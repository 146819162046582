import { Button } from '@material-ui/core';
import React from 'react';
import { Link, Redirect } from 'react-router-dom';

import Box from '../components/Box/Box';
import InnerLayout from '../components/InnerLayout/InnerLayout';
import { Layout } from '../components/Layout/Layout';
import { Loading } from '../components/Loading';
import { Alert } from '../services/alert';
import UserManager from '../services/manager/UserManager';
import { Helmet } from 'react-helmet';
import { User } from '../interfaces/user';

interface Props {
  location: any;
}

interface State {
  loading: boolean;
  sent: boolean;
  userId?: number;
  user?: User;
}

export default class VerifyAccount extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      loading: true,
      sent: false,
    };
  }

  async componentDidMount() {
    const token = this.props.location.search.substr(7).split('.');

    let tokenData = JSON.parse(atob(token[1]));
    tokenData = tokenData.userId;

    const user = await UserManager.getUserById(tokenData);

    try {
      await UserManager.verify(this.props.location.search.substr(7));

      if (user.userInfo.newsletter) {
        await UserManager.registerNewsletterByUserId(tokenData, 3);
      }
    } catch (e) {
      Alert.error(
        'Konto aktivieren',
        'Fehler beim Aktivieren des Kontos. Bitte versuchen Sie es später erneut oder wenden Sie sich an den Support.'
      );
      this.setState({ loading: false });
      return;
    }

    this.setState({
      sent: true,
      loading: false,
    });
  }

  render() {
    return (
      <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Account aktivieren - OLBplus Vorteilsportal</title>
        </Helmet>
        {this.state.loading && (
          <InnerLayout>
            <Loading />
          </InnerLayout>
        )}

        {this.state.sent && (
          <InnerLayout>
            <Box alternativeColor>
              <Box>
                <h2>OLBplus Konto aktiviert</h2>

                <p>
                  Ihr OLBplus Konto wurde aktiviert. Sie können sich jetzt mit
                  Ihren Zugangsdaten anmelden.
                </p>

                <Link to="/login">
                  <Button color="primary" variant="contained">
                    Zum Login
                  </Button>
                </Link>
              </Box>
            </Box>
          </InnerLayout>
        )}

        {!this.state.loading && !this.state.sent && <Redirect to="/" />}
      </Layout>
    );
  }
}

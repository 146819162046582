import { Grid } from "@material-ui/core";
import React from "react";
import { Helmet } from "react-helmet";

import Box from "../../components/Box/Box";
import Headline from "../../components/Headline/Headline";
import InnerLayout from "../../components/InnerLayout/InnerLayout";
import { Layout } from "../../components/Layout/Layout";
import { Loading } from "../../components/Loading";
import { ContentElements } from "../../interfaces/ContentElements";
import CmsManager from "../../services/manager/CmsManager";
import StateManager from "../../services/manager/StateManager";

interface Props {}

interface State {
  loading: boolean;
  content?: ContentElements;
}

export default class Terms extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      loading: true,
    };
  }

  async componentDidMount() {
    StateManager.resetProductsFilter();
    StateManager.resetShopFilter();

    const content = await CmsManager.getPageContentsCleaned("agb");

    this.setState({
      content,
      loading: false,
    });
  }

  render() {
    return (
      <Layout>
        <div className="Terms">
          <Helmet>
            <meta charSet="utf-8" />
            <title>Datenschutz - OLBplus Vorteilsportal</title>
          </Helmet>
          {this.state.loading && (
            <InnerLayout>
              <Loading />
            </InnerLayout>
          )}

          {!this.state.loading && (
            <InnerLayout>
              <Box>
                {this.state.content && this.state.content.pageTitle && (
                  <h1>{this.state.content.pageTitle}</h1>
                )}

                <Grid container spacing={2}>
                  {this.state.content && this.state.content.pageDescription && (
                    <Grid item xs={12} lg={4}>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: this.state.content.pageDescription as string,
                        }}
                      ></div>
                    </Grid>
                  )}

                  <Grid item xs={12} lg={8}>
                    {this.state.content &&
                      Object.keys(this.state.content).map(
                        (item: string, index) => {
                          if (
                            this.state.content &&
                            item !== "pageTitle" &&
                            item !== "pageDescription" &&
                            item.endsWith("Title")
                          ) {
                            return (
                              <div style={{ width: "80%" }} key={index}>
                                <Headline type="h3">
                                  {this.state.content[item]}
                                </Headline>

                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: this.state.content[
                                      item.slice(0, -5)
                                    ] as string,
                                  }}
                                ></p>
                              </div>
                            );
                          } else {
                            return "";
                          }
                        }
                      )}
                  </Grid>
                </Grid>
              </Box>
            </InnerLayout>
          )}
        </div>
      </Layout>
    );
  }
}

import './ShopDetail.scss';

import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { faStar } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Grid } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import copyToClipboard from 'copy-to-clipboard';
import * as React from 'react';
import { Fragment } from 'react';
import { Helmet } from 'react-helmet';

import { AuthButton } from '../../components/AuthButton';
import Backlink from '../../components/Backlink/Backlink';
import Box from '../../components/Box/Box';
import Headline from '../../components/Headline/Headline';
import InnerLayout from '../../components/InnerLayout/InnerLayout';
import { Layout } from '../../components/Layout/Layout';
import { Loading } from '../../components/Loading';
import ResponsiveTable from '../../components/ResponsiveTable/ResponsiveTable';
import ShopTile from '../../components/ShopTile/ShopTile';
import Shop from '../../interfaces/shop';
import Auth from '../../services/auth';
import Helper from '../../services/helper';
import CashbackManager from '../../services/manager/CashbackManager';
import StateManager from '../../services/manager/StateManager';

interface Props {
  match: any;
  history: any;
}

interface State {
  loading: boolean;
  shop?: Shop;
  similarShops: Shop[];
  visibleVoucherId: number;
  showCopyFeedback: boolean;
  isFavorite: boolean;
  currentFilter: any;
}

export default class ShopDetail extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      loading: true,
      similarShops: [],
      visibleVoucherId: 0,
      showCopyFeedback: false,
      isFavorite: false,
      currentFilter: {},
    };

    this.setVisibleVoucherId = this.setVisibleVoucherId.bind(this);
    this.copyToClipboard = this.copyToClipboard.bind(this);
    this.handleToggleFavorite = this.handleToggleFavorite.bind(this);
  }

  async componentDidMount() {
    const [shop, currentFilter] = await Promise.all([
      CashbackManager.findShop(this.props.match.params.id),
      StateManager.getState('cashback'),
    ]);

    const similarShops: Shop[] = [];

    if (shop.similarShops) {
      shop.similarShops.forEach(async (shopId) => {
        const similarShop = await CashbackManager.findShop(shopId);
        similarShops.push(similarShop);
      });
    }

    this.setState({
      shop,
      loading: false,
      similarShops,
      isFavorite: shop.isFavorite,
      currentFilter,
    });
  }

  setVisibleVoucherId(id: number) {
    this.setState({
      visibleVoucherId: id,
    });
  }

  copyToClipboard(code?: any) {
    if (!code) return;
    copyToClipboard(code);
    this.setState({
      showCopyFeedback: true,
    });
  }

  async handleToggleFavorite() {
    if (!this.state.shop) return;

    if (this.state.isFavorite) {
      await CashbackManager.removeShopFavorite(this.state.shop.id);
    } else {
      await CashbackManager.setShopFavorite(this.state.shop.id);
    }

    this.setState({
      isFavorite: !this.state.isFavorite,
    });
  }

  render() {
    return (
      <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Shopansicht - OLBplus Vorteilsportal</title>
        </Helmet>
        <InnerLayout className="ShopDetail">
          {this.state.loading && <Loading />}

          {!this.state.loading && this.state.shop && (
            <Box alternativeColor>
              <Backlink
                label="Zurück zur Übersicht"
                blackLink
                state={this.state.currentFilter}
              />

              <Grid container spacing={2}>
                <Grid item md={9}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Box>
                        <Headline type="h1" size="small">
                          {this.state.shop.name}
                        </Headline>

                        <p
                          dangerouslySetInnerHTML={{
                            __html:
                              (this.state.shop.description as string) || '',
                          }}
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={12}>
                      <Box>
                        <Headline type="h2" size="small">
                          Cashbackübersicht
                        </Headline>

                        <ResponsiveTable
                          columns={[
                            {
                              name: 'description',
                              label: 'Beschreibung',
                              textAlign: 'left',
                            },
                            {
                              name: 'value',
                              label: 'Cashbackwert',
                              textAlign: 'right',
                            },
                          ]}
                          data={
                            this.state.shop.cashbackRates &&
                            this.state.shop.cashbackRates.map(
                              (cashbackRate, key) => ({
                                description: cashbackRate.description,
                                value: `${Helper.formatNumber(
                                  cashbackRate.amount,
                                  2
                                )} ${cashbackRate.type}`,
                              })
                            )
                          }
                          styleType="benefits"
                        />
                        <br />

                        <AuthButton
                          color="primary"
                          variant="contained"
                          size="medium"
                          href={this.state.shop.link}
                          target="_blank"
                          onClick={(e: any) =>
                            Helper.handleClickoutHint(
                              {
                                type: 'shop',
                                url: this.state?.shop?.link,
                                shopId: this.state?.shop?.id,
                              },
                              e,
                              this.props.history
                            )
                          }
                        >
                          Zum Shop
                        </AuthButton>
                      </Box>
                    </Grid>

                    <Grid item xs={12}>
                      <Box>
                        <Headline type="h2" size="small">
                          Gutscheine
                        </Headline>
                        {this.state.shop.vouchers &&
                          this.state.shop.vouchers.length === 0 && (
                            <p>Aktuell liegen keine Gutscheine vor.</p>
                          )}

                        {this.state.shop.vouchers &&
                          this.state.shop.vouchers.length > 0 &&
                          this.state.shop.vouchers.map((voucher, key) => (
                            <div className="ShopDetail-vouchers" key={key}>
                              <div className="ShopDetail-voucher">
                                <div className="voucherIntro">
                                  <Headline
                                    type="h3"
                                    color="secondary"
                                    style={{ marginBottom: 0 }}
                                  >
                                    {voucher.title}
                                  </Headline>

                                  {Auth.isLoggedIn() &&
                                    this.state.visibleVoucherId !==
                                      voucher.id &&
                                    voucher.code && (
                                      <Button
                                        variant="outlined"
                                        size="medium"
                                        color="primary"
                                        onClick={() => {
                                          this.setVisibleVoucherId(voucher.id);
                                        }}
                                      >
                                        Gutscheincode anzeigen
                                      </Button>
                                    )}

                                  {Auth.isLoggedIn() &&
                                    this.state.visibleVoucherId ===
                                      voucher.id &&
                                    voucher.code && (
                                      <AuthButton
                                        variant="outlined"
                                        size="medium"
                                        color="primary"
                                        onClick={() =>
                                          this.copyToClipboard('voucher.code')
                                        }
                                      >
                                        Gutscheincode: {voucher.code}
                                      </AuthButton>
                                    )}
                                </div>

                                <p
                                  className="voucherDescription"
                                  dangerouslySetInnerHTML={{
                                    __html: voucher.description || '',
                                  }}
                                ></p>

                                <div className="ShopDetail-voucher-info">
                                  <Headline type="h4" color="secondary">
                                    {voucher.mimimumOrderValue &&
                                      parseInt(
                                        `${voucher.mimimumOrderValue}`,
                                        10
                                      ) > 0 && (
                                        <span>
                                          Mindestbestellwert:{' '}
                                          {Helper.formatPrice(
                                            voucher.mimimumOrderValue,
                                            'EUR'
                                          )}
                                        </span>
                                      )}
                                  </Headline>

                                  <Headline
                                    type="h4"
                                    color="secondary"
                                    style={{ textAlign: 'right' }}
                                  >
                                    Gültig bis:{' '}
                                    {Helper.formatDate(voucher.validTo)}
                                  </Headline>
                                </div>
                              </div>
                            </div>
                          ))}
                      </Box>
                    </Grid>

                    {this.state.shop.important && (
                      <Grid item xs={12}>
                        <Box>
                          <Headline type="h2" size="small">
                            Bitte beachten Sie bei {this.state.shop.name}
                          </Headline>

                          <p
                            dangerouslySetInnerHTML={{
                              __html: this.state.shop.important || '',
                            }}
                          ></p>
                        </Box>
                      </Grid>
                    )}
                  </Grid>
                </Grid>

                <Grid item md={3}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Box>
                        <img src={this.state.shop.logo} width="100%" alt="" />

                        {this.state.shop.maximumCashback && (
                          <p style={{ textAlign: 'center' }}>
                            Bis zu{' '}
                            <span className="color-secondary">
                              {Helper.formatNumber(
                                this.state.shop.maximumCashback,
                                2
                              )}{' '}
                              {this.state.shop.maximumCashbackType}
                            </span>{' '}
                            bei {this.state.shop.name}
                          </p>
                        )}

                        <div
                          style={{
                            lineHeight: '200%',
                            textAlign: 'center',
                            marginTop: '1em',
                          }}
                        >
                          {Auth.isLoggedIn() && (
                            <div>
                              {!this.state.isFavorite && (
                                <a onClick={this.handleToggleFavorite} href="#">
                                  Als Favorit markieren{' '}
                                  <span
                                    style={{
                                      color: '#E7E7E7',
                                      marginLeft: '5px',
                                    }}
                                  >
                                    <FontAwesomeIcon icon={faStar} />
                                  </span>
                                </a>
                              )}

                              {this.state.isFavorite && (
                                <a onClick={this.handleToggleFavorite} href="#">
                                  Favorit löschen{' '}
                                  <span style={{ marginLeft: '5px' }}>
                                    <FontAwesomeIcon icon={faStar} />
                                  </span>
                                </a>
                              )}
                            </div>
                          )}

                          <AuthButton
                            color="primary"
                            variant="contained"
                            size="medium"
                            href={this.state.shop.link}
                            target="_blank"
                            style={{ marginTop: '15px' }}
                            onClick={(e: any) =>
                              Helper.handleClickoutHint(
                                {
                                  type: 'shop',
                                  url: this.state?.shop?.link,
                                  shopId: this.state?.shop?.id,
                                },
                                e,
                                this.props.history
                              )
                            }
                          >
                            Zum Shop
                          </AuthButton>
                        </div>
                      </Box>
                    </Grid>

                    <Grid item xs={12}>
                      <Box>
                        <Headline
                          type="h3"
                          color="secondary"
                          style={{ marginTop: 0 }}
                        >
                          Allgemeine Informationen
                        </Headline>

                        <p>
                          Bitte achten Sie darauf, dass Gutscheine meist nicht
                          mit dem angegebenen Cashback kombiniert werden können.
                          Dies gilt auch für käuflich erworbene Gutscheine im
                          Shop selbst oder von Groupon o.ä. Die Verwendung von
                          Gutscheinen führt in den meisten Fällen zur Ablehnung
                          des Bonus.
                        </p>

                        <p>
                          Alle prozentualen Rabatte beziehen sich auf den
                          Bruttoeinkaufswert. Versandkosten werden nicht mit in
                          die Bonusberechnung einbezogen, der Bruttoeinkaufswert
                          ist damit im Normalfall etwas geringer als der
                          Rechnungsbetrag.
                        </p>

                        <p>
                          Nachbuchungsanfragen müssen spätestens 3 Monate nach
                          Buchung versendet werden. Anfragen, die später
                          eingehen werden nicht berücksichtigt.
                        </p>
                      </Box>
                    </Grid>

                    {this.state.similarShops.length > 0 && (
                      <Grid item md={3}>
                        <Box>
                          <h3>Vielleicht auch interessant</h3>

                          <Grid container spacing={2}>
                            {this.state.similarShops.map((shop, key) => (
                              <Grid item xs={6} md={4} key={key}>
                                <ShopTile
                                  image={shop.logo}
                                  buttonLabel="Zum Shop"
                                  morePath={`/shop/${shop.id}`}
                                  buttonLink={shop.link}
                                  isFavorite={shop.isFavorite}
                                  history={this.props.history}
                                  shopId={shop.id}
                                >
                                  {shop.maximumCashback && (
                                    <Fragment>
                                      Bis zu{' '}
                                      <strong>
                                        {Helper.formatNumber(
                                          shop.maximumCashback,
                                          2
                                        )}{' '}
                                        {shop.maximumCashbackType}
                                      </strong>{' '}
                                      bei {shop.name}
                                    </Fragment>
                                  )}
                                </ShopTile>
                              </Grid>
                            ))}
                          </Grid>
                        </Box>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          )}

          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            open={this.state.showCopyFeedback}
            autoHideDuration={5000}
            onClose={() => this.setState({ showCopyFeedback: false })}
            ContentProps={{
              'aria-describedby': 'message-id',
            }}
            message={<span id="message-id">Gutscheincode wurde kopiert</span>}
            action={[
              <IconButton
                key="close"
                aria-label="close"
                color="inherit"
                onClick={() => this.setState({ showCopyFeedback: false })}
              >
                <FontAwesomeIcon icon={faTimes} />
              </IconButton>,
            ]}
          />
        </InnerLayout>
      </Layout>
    );
  }
}

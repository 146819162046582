import { Grid, Button } from '@material-ui/core';
import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import Box from '../../components/Box/Box';
import InnerLayout from '../../components/InnerLayout/InnerLayout';
import { Layout } from '../../components/Layout/Layout';
import { Loading } from '../../components/Loading';
import { ContentElements } from '../../interfaces/ContentElements';
import CmsManager from '../../services/manager/CmsManager';
import UserManager from '../../services/manager/UserManager';
import Auth from '../../services/auth';

interface Props {
  location: any;
}

interface State {
  loading: boolean;
  content?: ContentElements;
}

export default class NewsletterOptOut extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      loading: true,
    };

    this.unsubscribewithoutLogin = this.unsubscribewithoutLogin.bind(this);
  }

  async componentDidMount() {
    const content = await CmsManager.getPageContentsCleaned(
      'newsletter-cancellation'
    );

    try {
      Auth.isLoggedIn()
        ? await UserManager.registerNewsletter(4)
        : await this.unsubscribewithoutLogin();
    } catch (error) {
      console.error('error unsubscribing from newsletter: ', error);

      return this.setState({
        loading: false,
        content: {
          pageTitle: 'Das hat leider nicht geklappt...',
          pageDescription: 'Da ist wohl etwas schiefgelaufen.',
        },
      });
    }

    this.setState({
      content,
      loading: false,
    });
  }

  unsubscribewithoutLogin = async () => {
    const email = this.props.location.search.substr(7);
    if (!email) throw new Error('no email address');

    const user = await UserManager.getUserByEmail(email);

    const id =
      user?.id &&
      user.id
        .split('')
        .filter((e) => [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].includes(Number(e)))
        .join('');

    if (!id) throw new Error('no user id');

    const patchedUser = await UserManager.registerNewsletterByUserId(
      Number(id),
      4
    );

    if (patchedUser?.userInfo?.newsletter !== '4')
      throw new Error('user patch failed');
  };

  render() {
    return (
      <Layout>
        <div className="newsletterRegistration">
          <Helmet>
            <meta charSet="utf-8" />
            <title>Newsletter-Abmeldung – OLBplus Vorteilsportal</title>
          </Helmet>
          {this.state.loading && (
            <InnerLayout>
              <Loading />
            </InnerLayout>
          )}

          {!this.state.loading && (
            <InnerLayout>
              <Box alternativeColor>
                <Box>
                  {this.state.content && this.state.content.pageTitle && (
                    <h1>{this.state.content.pageTitle}</h1>
                  )}

                  <Grid container spacing={2}>
                    {this.state.content && this.state.content.pageDescription && (
                      <Grid item xs={12} lg={4}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: this.state.content
                              .pageDescription as string,
                          }}
                        ></div>
                      </Grid>
                    )}
                  </Grid>

                  <Link to="/">
                    <Button color="primary" variant="contained">
                      Zur Startseite
                    </Button>
                  </Link>
                </Box>
              </Box>
            </InnerLayout>
          )}
        </div>
      </Layout>
    );
  }
}

import { Button, Checkbox, FormControlLabel, Grid } from "@material-ui/core";
import React from "react";
import { Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";

import Backlink from "../../components/Backlink/Backlink";
import Headline from "../../components/Headline/Headline";
import InnerLayout from "../../components/InnerLayout/InnerLayout";
import { Layout } from "../../components/Layout/Layout";
import { Loading } from "../../components/Loading";
import StateManager from "../../services/manager/StateManager";
import UserManager from "../../services/manager/UserManager";
import Box from "./../../components/Box/Box";

interface State {
  loading: boolean;
  redirect: boolean;

  interests: any;
}

interface Props {}

export default class ChangeInterests extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      loading: true,
      redirect: false,

      interests: {},
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async componentDidMount() {
    StateManager.resetProductsFilter();
    StateManager.resetShopFilter();

    const user = await UserManager.me();

    let interests = JSON.parse(
      user.userInfo.interests ? user.userInfo.interests : "{}"
    );
    if (!interests) interests = {};

    this.setState({
      loading: false,
      interests,
    });
  }

  handleChange(field: string) {
    return (e: any) => {
      const interests = this.state.interests;

      interests[field] = e.target.checked;

      this.setState({
        interests,
      });
    };
  }

  async handleSubmit() {
    await UserManager.updateSettings({
      interests: JSON.stringify(this.state.interests),
    });

    this.setState({
      redirect: true,
    });
  }

  render() {
    const interests = this.state.interests;

    if (this.state.redirect) return <Redirect to="/ihre-daten" />;

    return (
      <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Interessen bearbeiten - OLBplus Vorteilsportal</title>
        </Helmet>
        {this.state.loading && (
          <InnerLayout>
            <Loading />
          </InnerLayout>
        )}

        {!this.state.loading && (
          <InnerLayout className="Settings">
            <Box>
              <Backlink label="Zurück zur Übersicht" to="/ihre-daten" />

              <Headline type="h1">Ihre Interessen</Headline>
            </Box>

            <Box alternativeColor>
              <Box>
                <Grid container spacing={2}>
                  {[
                    {
                      label: "Immobilien",
                      value: interests.realEstate,
                      field: "realEstate",
                    },
                    {
                      label: "Familie",
                      value: interests.family,
                      field: "family",
                    },
                    {
                      label: "Sport",
                      value: interests.sports,
                      field: "sports",
                    },
                    { label: "Kunst", value: interests.art, field: "art" },
                    {
                      label: "Kultur",
                      value: interests.culture,
                      field: "culture",
                    },
                    {
                      label: "Autos/Motorräder",
                      value: interests.cars,
                      field: "cars",
                    },
                    { label: "Musik", value: interests.music, field: "music" },
                    {
                      label: "Mobilität",
                      value: interests.mobility,
                      field: "mobility",
                    },
                    {
                      label: "Energie sparen",
                      value: interests.energy,
                      field: "energy",
                    },
                    {
                      label: "Reisen",
                      value: interests.travel,
                      field: "travel",
                    },
                    {
                      label: "Versicherungsprodukte",
                      value: interests.insuranceProducts,
                      field: "insuranceProducts",
                    },
                  ].map(({ label, value, field }, key) => (
                    <Grid item md={6} xs={12} key={key}>
                      <span
                        style={{
                          color: "#333333",
                        }}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={value}
                              color="default"
                              value="default"
                              onChange={
                                field ? this.handleChange(field) : () => {}
                              }
                            />
                          }
                          label={label}
                        />
                      </span>
                    </Grid>
                  ))}
                </Grid>

                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={12}
                    lg={12}
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "flex-end",
                    }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={this.handleSubmit}
                    >
                      Speichern
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </InnerLayout>
        )}
      </Layout>
    );
  }
}

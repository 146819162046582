import "./Footer.scss";

import { Container } from "@material-ui/core";
import React from "react";
import { NavLink } from "react-router-dom";

export default function Footer(props: { items: any[] }) {
  return (
    <div className="footer">
      <div>
        <Container>
          <div className="footer-wrapper">
            <div className="logo">
              <NavLink to={"/"}>
                <img src="/assets/images/OLB_Logo_neg_RGB.svg" alt="" />
              </NavLink>

              <div className="mehrwerkHint">
                <span>In Kooperation mit</span>
                <img
                  className="mehrwerk"
                  src="/assets/images/logo_mehrwerk.svg"
                  alt=""
                />
              </div>
            </div>

            <nav className="footerText">
              <span className="footerText">
                Eine Leistung der Mehrwerk GmbH
                <br />
                (kein Vertragsschluss mit der Oldenburgischen Landesbank AG)
                <br />
                &copy; 2020
                <span className="hideOnMobile">&nbsp;|&nbsp;&nbsp;</span>
              </span>

              {props.items.map((item, index) => {
                if (item.link.indexOf("http") === -1) {
                  return (
                    <NavLink
                      key={"footer-nav-" + index}
                      activeClassName="active"
                      to={item.link}
                    >
                      {item.title}
                    </NavLink>
                  );
                }

                if (item.link.indexOf("http") === 0) {
                  return (
                    <a
                      target="_blank"
                      key={"footer-nav-" + index}
                      href={item.link}
                      rel="noopener noreferrer"
                    >
                      {item.title}
                    </a>
                  );
                }
                return false;
              })}
            </nav>
          </div>
        </Container>
      </div>
    </div>
  );
}

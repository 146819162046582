import { Grid, Button } from '@material-ui/core';
import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import Box from '../../components/Box/Box';
import InnerLayout from '../../components/InnerLayout/InnerLayout';
import { Layout } from '../../components/Layout/Layout';
import { Loading } from '../../components/Loading';
import { ContentElements } from '../../interfaces/ContentElements';
import CmsManager from '../../services/manager/CmsManager';
import UserManager from '../../services/manager/UserManager';
import Auth from '../../services/auth';

interface Props {
  location: any;
}

interface State {
  loading: boolean;
  content?: ContentElements;
}

export default class NewsletterRegistration extends React.Component<
  Props,
  State
> {
  constructor(props: Props) {
    super(props);

    this.state = {
      loading: true,
    };
  }

  async componentDidMount() {
    const content = await CmsManager.getPageContentsCleaned(
      'newsletter-registration'
    );

    const token = this.props.location.search.substr(7).split('.');

    let tokenData = JSON.parse(atob(token[1]));
    tokenData = tokenData.userId;

    const user = await UserManager.getUserById(tokenData);

    if ((user.userInfo.newsletter = '3')) {
      await UserManager.registerNewsletterByUserId(tokenData, 1);
    }

    this.setState({
      content,
      loading: false,
    });
  }

  render() {
    return (
      <Layout>
        <div className="newsletterRegistration">
          <Helmet>
            <meta charSet="utf-8" />
            <title>Newsletteranmeldung - OLBplus Vorteilsportal</title>
          </Helmet>
          {this.state.loading && (
            <InnerLayout>
              <Loading />
            </InnerLayout>
          )}

          {!this.state.loading && (
            <InnerLayout>
              <Box alternativeColor>
                <Box>
                  {this.state.content && this.state.content.pageTitle && (
                    <h1>{this.state.content.pageTitle}</h1>
                  )}

                  <Grid container spacing={2}>
                    {this.state.content && this.state.content.pageDescription && (
                      <Grid item xs={12} lg={4}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: this.state.content
                              .pageDescription as string,
                          }}
                        ></div>
                      </Grid>
                    )}
                  </Grid>

                  <Link to="/login">
                    <Button color="primary" variant="contained">
                      Zum Login
                    </Button>
                  </Link>
                </Box>
              </Box>
            </InnerLayout>
          )}
        </div>
      </Layout>
    );
  }
}

import './Travel.scss';

import { Grid } from '@material-ui/core';
import React from 'react';
import { Helmet } from 'react-helmet';

import { AuthButton } from '../../components/AuthButton';
import Backlink from '../../components/Backlink/Backlink';
import Box from '../../components/Box/Box';
import Headline from '../../components/Headline/Headline';
import ImageSlider from '../../components/ImageSlider/ImageSlider';
import InnerLayout from '../../components/InnerLayout/InnerLayout';
import { Layout } from '../../components/Layout/Layout';
import { Loading } from '../../components/Loading';
import { ContentElements } from '../../interfaces/ContentElements';
import { DownloadLink } from '../../interfaces/downloadLink';
import Shop from '../../interfaces/shop';
import { User } from '../../interfaces/user';
import Auth from '../../services/auth';
import CashbackManager from '../../services/manager/CashbackManager';
import StateManager from '../../services/manager/StateManager';
import UserManager from '../../services/manager/UserManager';
import CmsManager from './../../services/manager/CmsManager';

interface State {
  cashbackShops: Shop[];
  loading: boolean;
  content?: ContentElements;
  user?: User;
  sliderContent: string | DownloadLink[];
}

interface Props {}

export default class TravelBookingPauschal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      cashbackShops: [],
      loading: true,
      sliderContent: "",
    };

    this.handleFavoriteChange = this.handleFavoriteChange.bind(this);
  }

  async componentDidMount() {
    StateManager.resetProductsFilter();
    StateManager.resetShopFilter();

    const [cashbackShops, content] = await Promise.all([
      CashbackManager.findShopByCategoryId("13", 1, 4),
      CmsManager.getPageContentsCleaned("reisen"),
    ]);

    let sliderContent = "";

    if (Auth.isLoggedIn()) {
      const user = await UserManager.me();

      this.setState({
        user,
      });

      if (
        user.userInfo._aditoBasicContractType ===
        "d039df5d-e363-4bdd-a940-7bee28a688a2"
      ) {
        // SGirokonto
        sliderContent = content.reisesliders as string;
      } else if (
        user.userInfo._aditoBasicContractType ===
        "b703a1ff-7a24-40bc-809e-80eed136fa3d"
      ) {
        // MGirokonto
        sliderContent = content.reisesliderm as string;
      } else if (
        user.userInfo._aditoBasicContractType ===
        "d4a9d4ff-e084-46ec-9b7e-1dce252d3b91"
      ) {
        // Girokonto Start
        sliderContent = content.reisesliderstart as string;
      } else if (
        user.userInfo._aditoBasicContractType ===
        "bfcd240e-371d-4539-a8c9-11e740de802f"
      ) {
        // LGirokonto
        sliderContent = content.reisesliderl as string;
      } else if (
        user.userInfo._aditoBasicContractType ===
        "3d7ff9bd-860c-4346-9254-c13a980622e0"
      ) {
        // XLGirokonto
        sliderContent = content.reisesliderxl as string;
      } else if (
        user.userInfo._aditoBasicContractType ===
        "5a225adc-df14-4ff5-81a2-13dfa634e0ee"
      ) {
        // XLMGirokonto
        sliderContent = content.reisesliderxl as string;
      }
    } else {
      sliderContent = content.reiseslidernichteingeloggt as string;
    }

    this.setState({
      cashbackShops: cashbackShops.items,
      content,
      loading: false,
      sliderContent,
    });

    if (Auth.isLoggedIn()) {
      const user = await UserManager.me();

      this.setState({
        user,
      });
    }
  }

  handleFavoriteChange(shop: Shop) {
    return async (isFavorite: boolean) => {
      // Update shop
      if (!isFavorite) {
        await CashbackManager.removeShopFavorite(shop.id);
      } else {
        await CashbackManager.setShopFavorite(shop.id);
      }

      // Update view
      const shops = this.state.cashbackShops;

      shops.forEach((s: Shop) => {
        if (s.id === shop.id) {
          s.isFavorite = isFavorite;
        }
      });

      this.setState({
        cashbackShops: shops,
      });
    };
  }

  render() {
    return (
      <Layout>
        <div className="Travel">
          <Helmet>
            <meta charSet="utf-8" />
            <title>Reisebuchung - OLBplus Vorteilsportal</title>
          </Helmet>
          {this.state.loading && (
            <InnerLayout>
              <Loading />
            </InnerLayout>
          )}

          {!this.state.loading &&
            this.state.content &&
            this.state.sliderContent && (
              <InnerLayout>
                <ImageSlider
                  images={[
                    {
                      src: "/assets/images/heroes/Reise.jpg",
                      contentBox: {
                        content: this.state.sliderContent as string,
                      },
                      showServiceTile: true,
                    },
                  ]}
                />

                <Box style={{ paddingBottom: 0 }}>
                  <Backlink
                    label="zurück zu Reise"
                    to="/reise"
                    style={{ paddingBottom: 0 }}
                  />
                </Box>

                {Auth.isLoggedIn() && (
                  <Box>
                    <Grid container spacing={2}>
                      <iframe
                        style={{ width: "100%", height: "80vh", border: "none" }}
                        title="ibe"
                        src={`https://buchung.reisewerk24.de/search/region?taid=olb-plus&colors=p-0b7858,pl-ebf4f2,pd-0b7858,a-fd8c01,al-fd8c01,ad-db7500,c-fd8c01,cd-db7500&agent=${ Auth.getFKN() }`}
                      ></iframe>
                    </Grid>
                  </Box>
                )}

                {!Auth.isLoggedIn() && (
                  <Box>
                    <div style={{ position: "relative" }}>
                      <img
                        src="/assets/images/travel-blurred.jpg"
                        alt=""
                        style={{
                          width: "100%",
                        }}
                      />

                      <div
                        style={{
                          position: "absolute",
                          display: "flex",
                          justifyContent: "center",
                          left: 0,
                          top: 100,
                          right: 0,
                        }}
                      >
                        <div className="loginRequired">
                          <Headline type="h2" color="white">
                            Bitte einloggen
                          </Headline>

                          <p style={{ color: "#ffffff" }}>
                            Bitte loggen Sie sich ein, damit Sie sich Ihr
                            Cashback bei der Reisebuchung sichern können.
                            Anschließend haben Sie die Möglichkeit die
                            gewünschte Reise auszuwählen und zu buchen.
                          </p>

                          <AuthButton
                            color="primary"
                            variant="contained"
                            size="medium"
                            style={{
                              alignSelf: "flex-end",
                              marginTop: "2em",
                            }}
                          >
                            Login
                          </AuthButton>
                        </div>
                      </div>
                    </div>
                  </Box>
                )}
              </InnerLayout>
            )}
        </div>
      </Layout>
    );
  }
}

import "./Settings.scss";

import { Checkbox, Grid } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import Backlink from "../../components/Backlink/Backlink";
import Headline from "../../components/Headline/Headline";
import InnerLayout from "../../components/InnerLayout/InnerLayout";
import { Layout } from "../../components/Layout/Layout";
import { Loading } from "../../components/Loading";
import ServiceTile from "../../components/ServiceTile/ServiceTile";
import { User } from "../../interfaces/user";
import UserManager from "../../services/manager/UserManager";
import Box from "./../../components/Box/Box";

interface State {
  loading: boolean;
  user?: User;
  contractId: string;
  plusCardNumber: string;
  plusCardNumberError?: string;
}

interface Props {}

export default class Settings extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      loading: true,
      contractId: "",
      plusCardNumber: "",
    };

    this.handleChangeContractId = this.handleChangeContractId.bind(this);
    this.handleSubmitContractId = this.handleSubmitContractId.bind(this);
    this.handleChangeplusCardNumber = this.handleChangeplusCardNumber.bind(
      this
    );
  }

  async componentDidMount() {
    const user = await UserManager.me();

    this.setState({
      loading: false,
      user,
    });
  }

  handleChangeContractId(e: any) {
    this.setState({
      contractId: e.target.value,
    });
  }

  async handleSubmitContractId() {
    if (this.state.contractId.trim() === "") return;

    this.setState({
      loading: true,
    });

    await UserManager.updateSettings({
      contractId: this.state.contractId.trim(),
    });

    const user = await UserManager.me();

    this.setState({
      loading: false,
      user,
    });
  }

  handleChangeplusCardNumber(e: any) {
    this.setState({
      plusCardNumber: e.target.value,
    });
  }

  render() {
    const interests = JSON.parse(
      this.state.user && this.state.user.userInfo.interests
        ? this.state.user.userInfo.interests
        : "{}"
    );

    return (
      <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Ihre Daten - OLBplus Vorteilsportal</title>
        </Helmet>
        {this.state.loading && (
          <InnerLayout>
            <Loading />
          </InnerLayout>
        )}

        {!this.state.loading && this.state.user && (
          <InnerLayout className="Settings">
            <Box>
              <Backlink label="Zurück zur Übersicht" to="/profil" />

              <Grid container spacing={2}>
                <Grid item xs={12} md={8}>
                  <Headline type="h1">Ihre Daten</Headline>

                  <Headline type="h2" color="secondary" styling="thin">
                    Folgende Daten sind in Ihrem Profil hinterlegt. An dieser
                    Stelle können Sie Ihre Zugangsdaten sowie Ihre Interessen
                    anpassen.
                  </Headline>
                </Grid>

                <Grid item md={4}>
                  <ServiceTile />
                </Grid>
              </Grid>
            </Box>

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box alternativeColor>
                  <Grid container spacing={2}>
                    <Grid item xs={12} style={{ marginBottom: 30 }}>
                      <Box>
                        <div className="headlineWrapper">
                          <Headline type="h2" size="small">
                            Persönliche Angaben
                          </Headline>
                        </div>

                        <Grid container spacing={2}>
                          {[
                            {
                              label: "Vorname",
                              value: this.state.user.userInfo.firstName,
                            },
                            {
                              label: "Nachname",
                              value: this.state.user.userInfo.lastName,
                            },
                          ]
                            .concat(
                              this.state.user.userInfo.contractId &&
                                this.state.user.userInfo.contractId.trim() !==
                                  ""
                                ? [
                                    {
                                      label: this.state.user?.userInfo
                                        .isFriendAccount
                                        ? "Freundschaftsvertrag"
                                        : "Kontonummer",
                                      value: this.state.user.userInfo
                                        .contractId,
                                    },
                                  ]
                                : []
                            )
                            .concat(
                              this.state.user.userInfo.affiliateNo &&
                                this.state.user.userInfo.affiliateNo.trim() !==
                                  ""
                                ? [
                                    {
                                      label: "Pluskartenkontonummer",
                                      value: `********${this.state.user.userInfo.affiliateNo.substr(
                                        this.state.user.userInfo.affiliateNo
                                          .length - 4
                                      )}`,
                                    },
                                  ]
                                : []
                            )
                            .map(({ label, value }, key) => (
                              <Grid item md={6} xs={12} key={key}>
                                <Grid container>
                                  <Grid item xs={5}>
                                    <span
                                      style={{
                                        color: "#333333",
                                      }}
                                    >
                                      {label}:
                                    </span>
                                  </Grid>
                                  <Grid item xs={7}>
                                    {value}
                                  </Grid>
                                </Grid>
                              </Grid>
                            ))}
                        </Grid>
                      </Box>
                    </Grid>

                    <Grid item xs={12} style={{ marginBottom: 30 }}>
                      <Box>
                        <div className="headlineWrapper">
                          <Headline type="h2" size="small">
                            Ihre Zugangsdaten
                          </Headline>

                          <Link to="/ihre-zugangsdaten">
                            Änderungen vornehmen
                          </Link>
                        </div>

                        <Grid container spacing={2}>
                          {[
                            {
                              label: "E-Mail-Adresse:",
                              value: this.state.user.email,
                            },
                            {
                              label: "Passwort",
                              value: "***********",
                            },
                          ].map(({ label, value }, key) => (
                            <Grid item md={6} xs={12} key={key}>
                              <Grid container>
                                <Grid item xs={5}>
                                  <span
                                    style={{
                                      color: "#333333",
                                    }}
                                  >
                                    {label}:
                                  </span>
                                </Grid>
                                <Grid item xs={7}>
                                  {value}
                                </Grid>
                              </Grid>
                            </Grid>
                          ))}
                        </Grid>
                      </Box>
                    </Grid>

                    <Grid item xs={12}>
                      <Box>
                        <div className="headlineWrapper">
                          <Headline type="h2" size="small">
                            Ihre Interessen
                          </Headline>

                          <Link to="/ihre-interessen">
                            Änderungen vornehmen
                          </Link>
                        </div>

                        <Grid container spacing={0}>
                          {[
                            {
                              label: "Immobilien",
                              value: interests.realEstate,
                            },
                            { label: "Familie", value: interests.family },
                            { label: "Sport", value: interests.sports },
                            { label: "Kunst", value: interests.art },
                            { label: "Kultur", value: interests.culture },
                            {
                              label: "Autos/Motorräder",
                              value: interests.cars,
                            },
                            { label: "Musik", value: interests.music },
                            { label: "Mobilität", value: interests.mobility },
                            {
                              label: "Energie sparen",
                              value: interests.energy,
                            },
                            { label: "Reisen", value: interests.travel },
                            {
                              label: "Versicherungsprodukte",
                              value: interests.insuranceProducts,
                            },
                          ].map(({ label, value }, key) => (
                            <Grid item md={6} xs={12} key={key}>
                              <span
                                style={{
                                  color: "#333333",
                                }}
                              >
                                <Checkbox
                                  disabled
                                  checked={value === true}
                                  color="default"
                                  value="default"
                                />
                                {label}
                              </span>
                            </Grid>
                          ))}
                        </Grid>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </InnerLayout>
        )}
      </Layout>
    );
  }
}
